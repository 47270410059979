import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../styleConstants';

const Hexagon = ({ children, ...props }) =>
  <div {...props} style={styles.hexagon}>
    <div style={[styles.beforeAndAfter, styles.before]} />
    {children}
    <div style={[styles.beforeAndAfter, styles.after]} />
  </div>;

Hexagon.propTypes = {
  children: PropTypes.node,
};

Hexagon.defaultProps = {
  children: null,
};

const styles = {
  hexagon: {
    position: 'relative',
    width: '118px',
    height: '68.13px',
    backgroundColor: colors.green,
    margin: '34.06px 0',
    zIndex: 0,
    ':hover': {
      opacity: '.9'
    }
  },
  beforeAndAfter: {
    position: 'absolute',
    width: 0,
    borderLeft: '59px solid transparent',
    borderRight: '59px solid transparent',
    zIndex: -1,
  },
  before: {
    bottom: '100%',
    borderBottom: `34.06px solid ${colors.green}`,
  },
  after: {
    top: '100%',
    width: 0,
    borderTop: `34.06px solid ${colors.green}`,
  }
};

export default Radium(Hexagon);
