const routes = {
  about: "/about",
  bazaar: "/nabidka-pouzitych-stroju",
  blog: "/blog",
  category: "/kategorie",
  certificates: "/certifikaty",
  contacts: "/kontakty",
  files: "/soubory-ke-stazeni",
  gdpr: "/gdpr",
  home: "/",
  legal: "/obchodni-podminky",
  materials: "/materialy",
  news: "/novinky",
  product: "/produkt",
  search: "/stroje",
  software: "/software",
  support: "/podpora",
}

export default routes
