import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
const ProductsList = props => (
  <ul style={styles.wrapper}>
    {props.children}
  </ul>
);

ProductsList.propTypes = {
  children: PropTypes.node
};

ProductsList.defaultProps = {
  children: null
};

const styles = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    padding: 0,
    listStyle: 'none'
  }
};

export default Radium(ProductsList);
