import React from 'react';
import PropTypes from 'prop-types';

const NotFound = ({ history, location: { pathname }, staticContext }) => {
  staticContext.status = 404; // eslint-disable-line no-param-reassign

  history.push('/');

  return (
    <div>
      <h1>404: Needle {pathname} not found in Haystack :(</h1>
      You can redirect to <a href="/">home</a>.
    </div>
  );
};

NotFound.propTypes = {
  history: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  staticContext: PropTypes.shape({
    status: PropTypes.number
  })
};

NotFound.defaultProps = {
  history: [],
  location: { pathname: '' },
  staticContext: {}
};


export default NotFound;
