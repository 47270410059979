import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
// import Client from './components/Client.react';
import Loader from '../components/Loader.react';
// import Clients from './Clients.react';
// import Demobus from './Demobus.react';
import Intro from './Intro.react';
import Searcher from './Searcher.react';
import Services from './Services.react';
import Showroom from './Showroom.react';
import Support from './Support.react';
import Layout from '../Feature/Layout/Basic.react';

class Homepage extends Component {
  render() {
    const { content } = this.props;

    return (
      <Layout>
        <Helmet title="Gravotech" />
        {Object.keys(content).length > 0 ?
          <div>
            <Intro content={content} />
            <Services content={content.service} />
            <Searcher />
            <Support content={content.support_content} image={content.support_image} title={content.support_title} />
            <Showroom content={content.showroom_content} image={content.showroom_image} title={content.showroom_title} />
            {/* <Clients>
              {content.clients.map(client =>
                <Client
                  key={client.name}
                  src={client.logo.url}
                >
                  {client.name}
                </Client>
              )}
            </Clients> */}
            {/* <Demobus content={content.demobus_content} image={content.demobus_image} title={content.demobus_title} /> */}
          </div> :
          <Loader style={{ margin: '32px auto' }} />
        }
      </Layout>
    );
  }
}

Homepage.defaultProps = {
  content: {},
};

Homepage.propTypes = {
  content: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  content: state.content.homepage,
});

export default connect(
  mapStateToProps,
  null
)(Homepage);
