import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Heading from '../../components/Heading.react';
import Loader from '../../components/Loader.react';
import ContentProvider from '../../ContentProvider.react';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';

class Legal extends Component {

  render() {
    const { content } = this.props;

    return (
      <Layout>
        <Helmet title="Obchodní podmínky" />
        <Container>
          <ContentProvider fetch={['legal']}>
            {Object.keys(content).length > 0 ?
              <div style={styles.wrapper}>
                <Heading>{RichText.asText(content.title)}</Heading>
                {RichText.render(content.content)}
              </div> :
              <Loader />
            }
          </ContentProvider>
        </Container>
      </Layout>
    );
  }
}

const styles = {
  wrapper: {
    margin: '32px 0'
  },
};

Legal.defaultProps = {
  content: {},
};

Legal.propTypes = {
  content: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  content: state.content.legal,
});

export default connect(
  mapStateToProps
)(Legal);
