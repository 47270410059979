import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import routes from '../routes';
import Icon from './Icon.react';
import Item from './NavigationItem.react';
import Link from './Link.react';
import listenWindowResize, { Device } from '../listenWindowResize.react';
import { media } from '../styleConstants';

class Navigation extends Component {
  render() {
    const { device } = this.props;

    return (
      <div style={styles.wrapper}>
        <div style={styles.menu}>
          {device.atLeast('l') &&
            <Icon color="#031419" kind="menu-shape-left" size={44} style={{ marginLeft: '-1px', marginTop: '-1px', verticalAlign: 'middle' }} />
          }
          <Item category="WP5-hyUAABhWbJ_K" />
          <Item category="WZCm6yUAAK0YW8HW" />
          <div style={styles.item}>
            <Link style={styles.link} to={routes.materials}>
              Materiály
            </Link>
          </div>
          {device.atLeast('l') &&
            <Icon color="#031419" kind="menu-shape-right" size={44} style={{ marginRight: '-1px', marginTop: '-1px', verticalAlign: 'middle' }} />
          }
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  device: PropTypes.instanceOf(Device).isRequired,
};

const styles = {
  wrapper: {
    background: '#031419',
    height: '44px',
    textAlign: 'center',
    position: 'relative',
  },
  menu: {
    display: 'inline-block',
    background: '#09AD14',
    height: '44px',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    [media.md]: {
      overflowX: 'hidden',
      width: 'auto',
    }
  },
  item: {
    display: 'inline-block',
    verticalAlign: 'middle',
    ':hover': {}
  },
  link: {
    display: 'inline-block',
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '14px 24px',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    }
  },
};

export default listenWindowResize(Radium(Navigation));
