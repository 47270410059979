import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import teamViewerImg from '../assets/images/teamviewer-icon-blue.png';
import Image from '../components/Image.react';
import Link from '../components/Link.react';

const Teamviewer = ({ link, title }) => (
  <Link to={link} target="_blank">
    <div style={styles.wrapper}>
      <Image src={teamViewerImg} style={{ height: '32px' }} />
      <div style={styles.title}>{title}</div>
    </div>
  </Link>
);

Teamviewer.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const styles = {
  wrapper: {
    background: 'rgba(255, 255, 255, .4)',
    border: '1px solid #fff',
    borderRadius: '2px',
    display: 'inline-block',
    minWidth: '160px',
    position: 'relative',
    textAlign: 'center',
    padding: '8px 16px 12px',
  },
  title: {
    color: '#2F6BCF',
    fontWeight: 700,
  },
};

export default Radium(Teamviewer);
