import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../styleConstants';

class Loader extends Component {
  render() {
    const { size, style } = this.props;

    const RATIO = 1.112;

    return (
      <div style={[styles.wrapper, style]}>
        <svg width={size} height={size * RATIO} viewBox="0 0 97 107.93">
          <path style={[styles.path, styles.path1]} d="M97,77a1.58,1.58,0,0,1-.63,1.53,1.54,1.54,0,0,1-1.64,0l-39.83-23A1.74,1.74,0,0,1,54,54a1.78,1.78,0,0,1,.9-1.53l39.9-23a1.34,1.34,0,0,1,1.52,0A1.58,1.58,0,0,1,97,30.93V77Z" />
          <path style={[styles.path, styles.path2]} d="M43.62,54a1.77,1.77,0,0,1-.88,1.53l-39.89,23a2,2,0,0,1-1.86,0A1.86,1.86,0,0,1,0,77V30.93A1.86,1.86,0,0,1,1,29.4a1.86,1.86,0,0,1,1.82,0l39.92,23A1.79,1.79,0,0,1,43.62,54Z" />
          <path style={[styles.path, styles.path3]} d="M94.23,24.8a1.77,1.77,0,0,1-.88,1.53l-39.89,23a1.58,1.58,0,0,1-1.67,0A1.68,1.68,0,0,1,51,47.84V1.77A1.65,1.65,0,0,1,53.5.24l39.87,23A1.75,1.75,0,0,1,94.23,24.8Z" />
          <path style={[styles.path, styles.path4]} d="M47,106.16a1.62,1.62,0,0,1-2.46,1.53l-39.86-23a1.75,1.75,0,0,1-.87-1.53,1.78,1.78,0,0,1,.89-1.53l39.9-23a1.54,1.54,0,0,1,1.64,0A1.66,1.66,0,0,1,47,60.09v46.07Z" />
          <path style={[styles.path, styles.path5]} d="M47,47.84a1.62,1.62,0,0,1-2.46,1.53l-39.86-23A1.75,1.75,0,0,1,3.8,24.8a1.78,1.78,0,0,1,.89-1.53l39.9-23a1.54,1.54,0,0,1,1.64,0A1.66,1.66,0,0,1,47,1.77V47.84Z" />
          <path style={[styles.path, styles.path6]} d="M94.23,83.13a1.77,1.77,0,0,1-.88,1.53l-39.89,23a1.58,1.58,0,0,1-1.67,0,1.68,1.68,0,0,1-.78-1.53V60.09a1.65,1.65,0,0,1,2.5-1.53l39.87,23A1.75,1.75,0,0,1,94.23,83.13Z" />
        </svg>
      </div>
    );
  }
}

Loader.propTypes = {
  size: PropTypes.number,
  style: PropTypes.shape({})
};

Loader.defaultProps = {
  size: 64,
  style: {}
};

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    width: '100%',
  },
  path: {
    animation: 'all 1.5s infinite ease-in-out',
    animationName: Radium.keyframes({
      '0%': {
        fill: colors.green,
      },
      '25%': {
        fill: colors.blueDark,
      },
      '50%': {
        fill: colors.green,
      },
      '100%': {
        fill: colors.green,
      }
    }),
    fill: colors.green,
  },
  path1: {
    animationDelay: 0,
  },
  path2: {
    animationDelay: '.2s',
  },
  path3: {
    animationDelay: '.4s',
  },
  path4: {
    animationDelay: '.6s',
  },
  path5: {
    animationDelay: '.8s',
  },
  path6: {
    animationDelay: '1s',
  },
};

export default Radium(Loader);
