import { configureStore } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { routerReducer as router } from 'react-router-redux';
import logger from 'redux-logger';
import promiseMiddleware from './lib/promiseMiddleware';
import content from './reducers/content';
import mailer from './reducers/mailer';

export const rootReducer = {
  content,
  mailer,
  form: formReducer,
  router
};

const createStore = (initialState = {}, { middlewares = [] }) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares, promiseMiddleware, logger),
    preloadedState: initialState,
    devTools: process.env.IS_BROWSER && window.devToolsExtension
  });
};

export default createStore;
