import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from './Button.react';
import Heading from './Heading.react';
import * as contentActions from '../common/actions/mailer';
import { colors } from '../styleConstants';

const validate = (values) => {
  const errors = {};
  if (!values.gdpr) {
    errors.gdpr = 'Je potřeba souhlasit se zpracováním osobních údajů';
  }

  return errors;
};

const renderGdprCheck = ({
  input,
  id,
  label,
  type,
  meta,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '24px' }}>
      <div style={{ flex: 0, paddingRight: '12px' }}>
        <input {...input} placeholder={label} type={type} id={id} />
      </div>
      <div style={{ flex: 1 }}>
        {meta.touched && (
          (meta.error && <div><span style={styles.error}>{meta.error}</span></div>)
          || (meta.warning && <span style={styles.warn}>{meta.warning}</span>)
        )}
        <label htmlFor={id} style={styles.gdpr}>
          Odesláním formuláře souhlasíte, že s Vašimi údaji bude nakládáno v souladu se zákonem č. 101/2000 Sb., o ochraně osobních údajů, ve znění pozdějších předpisů a Nařízením Evropského parlamentu a Rady (EU) 2016/679, obecným nařízením o ochraně osobních údajů. Účelem zpracování dat je vyřízení vaší poptávky.
        </label>
      </div>
    </div>);
};

const gdprRequired = value => (value ? undefined : 'Je potřeba souhlasit se zpracováním osobních údajů');

class ContactForm extends Component {
  componentDidMount() {
    const { clearRequestStatus } = this.props;
    clearRequestStatus();
  }

  componentWillReceiveProps(nextProps) {
    const { clearRequestStatus, requestState } = this.props;
    const { requestState: newRequestState } = nextProps;

    if (requestState === 'start' && newRequestState === 'success') {
      setTimeout(() => clearRequestStatus(), 4000);
    }
  }

  render() {
    const { handleSubmit, requestState, submitting, pristine, sendRequest, related } = this.props;

    const onSubmit = (values) => {
      const { name, phone, mail, message } = values;

      const content = `
  Jméno:
  ${name}
  
  Telefon:
  ${phone}
  
  E-mail:
  ${mail}
  
  Zpráva:
  ${message}
  
  Stránka:
  ${related}
      `;

      sendRequest('Zpráva z kontaktního formuláře', content);
    };

    return (
      <div style={styles.container}>
        <div style={styles.wrapper}>
          <Heading level={3}>Kontaktní formulář</Heading>
          {requestState && (
            <div style={styles.stateWrapper}>
              <div style={styles.stateText}>
                {requestState === 'start' && '...odesílám'}
                {requestState === 'success' && 'Zpráva odeslána, děkujeme'}
                {requestState === 'error' &&
                  'chyba při odesílání, prosíme o zavolání'}
              </div>
            </div>
          )}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="name" style={styles.label}>
              Vaše jméno
            </label>
            <Field
              name="name"
              component="input"
              type="text"
              style={styles.input}
            />
            <label htmlFor="phone" style={styles.label}>
              Váš telefon
            </label>
            <Field
              name="phone"
              component="input"
              type="text"
              style={styles.input}
            />
            <label htmlFor="mail" style={styles.label}>
              Váš e-mail
            </label>
            <Field
              name="mail"
              component="input"
              type="text"
              style={styles.input}
            />
            <label htmlFor="message" style={styles.label}>
              Zpráva
            </label>
            <Field
              name="message"
              component="textarea"
              style={styles.textarea}
            />
            <Button disabled={submitting || pristine} type="submit">odeslat</Button>
            <Field
              name="gdpr"
              id="gdpr"
              component={renderGdprCheck}
              type="checkbox"
              style={styles.checkbox}
              validate={[gdprRequired]}
            />
          </Form>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    backgroundColor: colors.lightGray,
    borderRadius: '3px',
    maxWidth: '640px',
    margin: '32px 0',
    padding: '16px 32px 32px',
    position: 'relative',
  },
  wrapper: {
    display: 'block',
    position: 'relative',
  },
  label: {
    display: 'block',
    marginBottom: '4px',
  },
  input: {
    border: '1px solid #eee',
    display: 'block',
    marginBottom: '8px',
    padding: '8px',
    width: '100%',
  },
  textarea: {
    border: '1px solid #eee',
    display: 'block',
    height: '100px',
    marginBottom: '8px',
    padding: '8px',
    width: '100%',
  },
  stateWrapper: {
    background: 'rgba(255, 255, 255, .9)',
    bottom: '16px',
    borderRadius: '3px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, .15)',
    left: '16px',
    position: 'absolute',
    right: '16px',
    textAlign: 'center',
    top: '16px',
  },
  stateText: {
    fontWeight: 'bold',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  gdpr: {
    color: colors.blueDark,
    fontSize: '12px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  warn: {
    color: colors.blueDark,
    fontSize: '12px',
  },
};

ContactForm.defaultProps = {
  name: '',
  phone: '',
  mail: '',
  message: '',
  related: '',
  requestState: '',
};

ContactForm.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  mail: PropTypes.string,
  message: PropTypes.string,
  related: PropTypes.string,
  requestState: PropTypes.string,
  sendRequest: PropTypes.func.isRequired,
  clearRequestStatus: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const selector = formValueSelector('contact');

const mapStateToProps = state => ({
  name: selector(state, 'name'),
  phone: selector(state, 'phone'),
  mail: selector(state, 'mail'),
  message: selector(state, 'message'),
  requestState: state.mailer.requestState,
});

const mapDispatchToProps = dispatch => ({
  clearRequestStatus: () => dispatch(contentActions.clearRequestStatus()),
  sendRequest: (subject, body) =>
    dispatch(contentActions.sendRequest(subject, body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'contact',
    validate,
    touchOnChange: true,
  })(Radium(ContactForm)),
);
