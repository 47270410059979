import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { rootReducer } from './common/createStore';

const store = configureStore({
  reducer: rootReducer,
  preloadedState: window.REDUX_STATE || {}
});

const BrowserProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
      {children}
    </BrowserRouter>
    </Provider>
  )
};

BrowserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default BrowserProvider;
