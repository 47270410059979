import Color from 'color';
import { RichText } from 'prismic-reactjs';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../components/Heading.react';
import Image from '../components/Image.react';
import Link from '../components/Link.react';
import routes from '../routes';
import { media } from '../styleConstants';
import { StyleRoot } from 'radium';

import gravographLogo from '../assets/images/gravotech-partner.png';

const Services = ({ content }) => (
  <StyleRoot>
    <div style={styles.wrapper}>
      <Link style={[styles.link, styles.left]} to={`${routes.category}/stroje-gravograph`}>
        <Image src={gravographLogo} style={{ width: '160px', margin: '10px 0' }} />
        {/* <Heading level={3} style={styles.heading}>{RichText.asText(content[0].title)}</Heading> */}
        <p style={styles.description}>{RichText.asText(content[0].description)}</p>
      </Link>
      <Link style={[styles.link, styles.right]} to={`${routes.category}/stroje-technifor`}>
        <Image src={gravographLogo} style={{ width: '160px', margin: '10px 0' }} />
        {/* <Heading level={3} style={styles.heading}>{RichText.asText(content[1].title)}</Heading> */}
        <p style={styles.description}>{RichText.asText(content[1].description)}</p>
      </Link>
    </div>
  </StyleRoot>
);

Services.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
};

const styles = {
  wrapper: {
    background: '#09AD14',
    textAlign: 'center',
    [media.s]: {
      display: 'flex',
    }
  },
  link: {
    boxSizing: 'border-box',
    color: '#fff',
    display: 'inline-block',
    padding: '16px 32px 24px',
    textDecoration: 'none',
    width: '100%',
    ':hover': {
      backgroundColor: Color('#031419').lighten(0.5).hex(),
      height: 400,
      color: '#ffcc00',
    },
    [media.s]: {
      flex: '1 1 auto',
      width: '50%',
    }
  },
  heading: {
    fontWeight: 700,
    marginBottom: '.25em',
    marginTop: 0
  },
  description: {
    fontSize: '1.15em',
    fontWeight: 600,
    margin: '10px 0',
    color: '#fff'
  },
  left: {
    borderColor: '#fff',
    borderStyle: 'solid',
    borderWidth: '2px 0 0',
    [media.s]: {
      borderWidth: '2px 1px 2px 0',
    }
  },
  right: {
    borderColor: '#fff',
    borderStyle: 'solid',
    borderWidth: '2px 0 2px',
    [media.s]: {
      borderWidth: '2px 0 2px 1px',
    }
  }
};

export default Radium(Services);
