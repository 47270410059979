import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Feature/Layout/Container.react';
import Link from '../components/Link.react';
import routes from '../routes';
import { colors } from '../styleConstants';

const Breadcrumbs = ({ paths }) => (
  <div style={styles.wrapper}>
    <Container>
      <Link style={[styles.base, styles.link]} to={routes.home}>Hlavní strana</Link>
      {paths && paths.map(link =>
        <span key={link.key} style={styles.item}>
          <span style={styles.separator}>/</span>
          {link.to ?
            <Link style={[styles.base, styles.link, link.active && styles.active]} to={link.to}>{link.label}</Link> :
            <span style={[styles.base, link.active && styles.active]}>{link.label}</span>
          }
        </span>
      )}
    </Container>
  </div>
);

Breadcrumbs.defaultProps = {
  paths: [],
};

Breadcrumbs.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.object),
};

const styles = {
  wrapper: {
    margin: 0,
    padding: '24px 0',
    width: '100%'
  },
  item: {
    display: 'inline-block'
  },
  base: {
    color: '#777',
  },
  link: {
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    }
  },
  active: {
    fontWeight: 700,
  },
  separator: {
    display: 'inline-block',
    color: colors.green,
    margin: '0 .5em'
  }
};

export default Radium(Breadcrumbs);
