import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../styleConstants';

const Container = ({ children, style, ...rest }) => (
  <div style={[styles.wrapper, style]} {...rest}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ])
};

Container.defaultProps = {
  style: {}
};

const styles = {
  wrapper: {
    margin: '0 auto',
    maxWidth: theme.container,
    padding: '0 32px'
  }
};

export default Radium(Container);
