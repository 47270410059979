import React from 'react';
import PropTypes from 'prop-types';

const Html = ({ children, ...props }) => (
  <div
    {...props}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: children }}
  />
);

Html.defaultProps = {
  children: ''
};

Html.propTypes = {
  children: PropTypes.string,
};

export default Html;
