import { RichText } from 'prismic-reactjs';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Button from '../../components/Button.react';
import Heading from '../../components/Heading.react';
import Image from '../../components/Image.react';
import Loader from '../../components/Loader.react';
import ContentProvider from '../../ContentProvider.react';
import theme from '../../styleConstants';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';
import Header from '../Category/Header.react';

class Certificates extends Component {
  render() {
    const { certificates } = this.props;

    return (
      <ContentProvider fetch={['certificates']}>
        {Object.keys(certificates).length ?
          <Layout>
            <Helmet title="Materiály" />
            <Header>
              <Heading>{RichText.asText(certificates.title)}</Heading>
              {RichText.render(certificates.content)}
            </Header>
            <Container>
              <div style={styles.files}>
                {certificates.gallery.length > 0 &&
                  certificates.gallery.map(doc =>
                    <div key={doc.description} style={styles.item}>
                      <Image src={doc.image.small.url} style={styles.image} />
                      <h2 style={styles.heading}>{doc.description}</h2>
                      <a href={doc.image.url} target="_blank" rel="noopener noreferrer">
                        <Button style={styles.button}>stáhnout certifikát</Button>
                      </a>
                    </div>
                  )
                }
              </div>
            </Container>
          </Layout>
          :
          <Loader />
        }
      </ContentProvider>
    );
  }
}

const styles = {
  files: {},
  item: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.secondary}`,
    padding: '20px'
  },
  image: {
    boxShadow: '1 1 16px 0 rgba(0, 0, 0, .15)',
    maxHeight: '120px',
    maxWidth: '120px',
  },
  button: {
  },
  heading: {
    margin: 0
  }
};

Certificates.defaultProps = {
  certificates: {},
};

Certificates.propTypes = {
  certificates: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  certificates: state.content.certificatesPage,
});

export default connect(
  mapStateToProps
)(Certificates);
