import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Layout/Container.react';
import hexagonsBg from '../../assets/images/hexagons_outlined.jpg';
import { media } from '../../styleConstants';

const Header = ({ children }) => (
  <div style={styles.wrapper}>
    <Container>
      <div style={styles.content}>
        {children}
      </div>
    </Container>
  </div>
);

Header.propTypes = {
  children: PropTypes.node
};

Header.defaultProps = {
  children: null
};

const styles = {
  wrapper: {
    backgroundImage: `url(${hexagonsBg})`,
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    margin: 0,
    padding: '24px 0',
    width: '100%'
  },
  content: {
    width: '100%',
    zIndex: 1,
    [media.s]: {
      width: '80%',
    },
    [media.m]: {
      width: '50%',
    },
  },
};

export default Radium(Header);
