import { RichText } from 'prismic-reactjs';
import Radium, { Style } from 'radium';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Player } from 'video-react';
import Heading from '../components/Heading.react';
import Logo from '../components/Logo.react';
import listenWindowResize, { Device } from '../listenWindowResize.react';
import { media } from '../styleConstants';

/* eslint-disable global-require */
const videourlmp4 = require('../assets/videos/gravotech_web.mp4');
const videourlwebm = require('../assets/videos/gravotech_web.webm');
const videoPoster = require('../assets/videos/video_poster.jpg');
/* eslint-enable global-require */

class Intro extends Component {
  render() {
    const { content, device } = this.props;

    return (
      <div style={styles.wrapper}>
        <div style={styles.content}>
          <Logo color="white" copyright size={device.atLeast('md') ? 80 : 50} />
          <br />
          <Heading style={styles.heading}>
            {RichText.asText(content.title)}
          </Heading>
          {device.atLeast('md') &&
            <p style={styles.perex}>{RichText.asText(content.perex)}</p>
          }
        </div>
        <div style={styles.overlay} />
        <Player
          autoPlay
          fluid
          loop
          muted
          playsInline
          poster={videoPoster}
          style={styles.video}
        >
          <source src={videourlmp4} />
          <source src={videourlwebm} />
        </Player>
        <Style
          scopeSelector=".video-react"
          rules={{
            height: '100%',
            position: 'relative',
            width: '100%',
            zIndex: 0,
            '.video-react-video': {
              minHeight: '100%',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              minWidth: '100%',
            },
            '.video-react-control-bar': {
              display: 'none'
            },
            button: {
              display: 'none'
            }
          }}
        />
      </div>
    );
  }
}

Intro.propTypes = {
  device: PropTypes.instanceOf(Device).isRequired,
};

const styles = {
  wrapper: {
    height: 'calc(99vh - 290px)',
    minHeight: '320px',
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    zIndex: 0
  },
  content: {
    color: '#fff',
    left: '50%',
    maxWidth: '720px',
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    zIndex: 2
  },
  heading: {
    [media.maxM]: {
      fontSize: '1.4em',
    }
  },
  perex: {
    fontSize: '20px'
  },
  overlay: {
    backgroundColor: 'rgba(3, 20, 25, .65)',
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 1
  },
};

Intro.defaultProps = {
  content: {},
};

Intro.propTypes = {
  content: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  content: state.content.homepage,
});

export default connect(mapStateToProps)(listenWindowResize(Radium(Intro)));
