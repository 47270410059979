import { RichText } from 'prismic-reactjs';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs.react';
import Heading from '../../components/Heading.react';
import Loader from '../../components/Loader.react';
import ContentProvider from '../../ContentProvider.react';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';
import ContactForm from '../../components/ContactForm.react';
import hexagonsBg from '../../assets/images/hexagons_outlined.jpg';
import { media } from '../../styleConstants';

class Contacts extends Component {
  render() {
    const { contacts } = this.props;

    return (
      <Layout>
        <Helmet title="Kontakty" />
        <div style={styles.wrapper}>
          <Breadcrumbs />
          <Container>
            <ContentProvider fetch={['contacts']}>
              {Object.keys(contacts).length ?
                <div>
                  <Heading>{RichText.asText(contacts.title)}</Heading>
                  {contacts.venues.length && contacts.venues.map(venue =>
                    <div key={RichText.asText(venue.name)} style={styles.venue}>
                      <Heading level={2}>{RichText.asText(venue.name)}</Heading>
                      <div style={styles.boxes}>
                        <div style={styles.box1}>
                          <Heading level={3}>{RichText.asText(venue.box_1_title)}</Heading>
                          {RichText.render(venue.box_1_content)}
                        </div>
                        <div style={styles.box2}>
                          <Heading level={3}>{RichText.asText(venue.box_2_title)}</Heading>
                          {RichText.render(venue.box_2_content)}
                        </div>
                        <div style={styles.box3}>
                          <Heading level={3}>{RichText.asText(venue.box_3_title)}</Heading>
                          {RichText.render(venue.box_3_content)}
                        </div>
                        <div style={styles.box4}>
                          <Heading level={3}>{RichText.asText(venue.box_4_title)}</Heading>
                          {RichText.render(venue.box_4_content)}
                        </div>
                        <div style={styles.box5}>
                          <iframe
                            frameBorder="0"
                            style={{ border: 0, minHeight: '240px', width: '100%' }}
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAPqoR5C0tzC_k4zUoBi05RDroNZGT9WIw&q=${RichText.asText(venue.box_4_content)}&center=${venue.map.latitude},${venue.map.longitude}&zoom=16`}
                            allowFullScreen
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  )}
                </div> :
                <Loader />
              }
            </ContentProvider>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ minWidth: '66%' }}>
                <ContactForm />
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  }
}

const styles = {
  wrapper: {
    backgroundImage: `url(${hexagonsBg})`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '66% auto',
    margin: 0,
    padding: '24px 0',
    width: '100%'
  },
  venue: {
    position: 'relative',
    margin: '32px 0'
  },
  boxes: {
    [media.m]: {
      display: 'flex',
    },
    flexWrap: 'wrap',
    margin: '0 -32px 32px'
  },
  box1: {
    flex: '1 1 auto',
    padding: '8px 32px',
    [media.m]: {
      width: '30%'
    }
  },
  box2: {
    flex: '1 1 auto',
    padding: '8px 32px',
    [media.m]: {
      width: '30%'
    }
  },
  box3: {
    flex: '1 1 auto',
    padding: '8px 32px',
    [media.m]: {
      width: '40%'
    }
  },
  box4: {
    flex: '1 1 auto',
    padding: '8px 32px',
    [media.m]: {
      width: '30%'
    }
  },
  box5: {
    flex: '1 1 auto',
    padding: '8px 32px',
    [media.m]: {
      width: '70%'
    }
  }
};

Contacts.defaultProps = {
  contacts: {},
};

Contacts.propTypes = {
  contacts: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  contacts: state.content.contacts,
});

export default connect(
  mapStateToProps,
)(Contacts);
