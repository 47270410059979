import moment from 'moment-business-days';
import Radium from 'radium';
import React from 'react';
import Link from '../components/Link.react';
import routes from '../routes';
import theme from '../styleConstants';

moment.locale('cs');
const format = 'hh:mm:ss';
const currentTime = moment(new Date(), format);
const openingTime = moment('08:00:00', format);
const closingTime = moment('17:00:00', format);
const opened = moment().isBusinessDay() && currentTime.isBetween(openingTime, closingTime);

const Bubble = () => (
  <Link style={styles.wrapper} to={routes.contacts}>
    {opened ?
      <span>Máme otevřeno</span> :
      <span>Zanechte nam vzkaz</span>
    }
  </Link>
);

const styles = {
  wrapper: {
    background: theme.secondary,
    bottom: 0,
    borderRadius: '8px 8px 0 0',
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, .2)',
    color: 'white',
    fontSize: '.75rem',
    fontWeight: 700,
    padding: '4px 8px',
    position: 'fixed',
    right: '12px',
    textDecoration: 'none',
    transition: '0.15s all ease',
    ':hover': {
      padding: '4px 8px 8px',
    }
  }
};

export default Radium(Bubble);
