import { RichText } from 'prismic-reactjs';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import routes from '../routes';
import Button from '../components/Button.react';
import Heading from '../components/Heading.react';
import Container from './../Feature/Layout/Container.react';
import { media } from '../styleConstants';
import hexMask from '../assets/images/hex_mask_right.png';

const Support = ({ content, image, title }) => (
  <Container style={styles.wrapper}>
    <div style={styles.content}>
      <Heading level={2}>{RichText.asText(title)}</Heading>
      {RichText.render(content)}
      <br />
      <Button to={routes.support}>více o servisu</Button>
    </div>
    <div style={styles.imageWrapper}>
      <div style={[styles.image, { backgroundImage: `url(${image.url})` }]} />
      <div style={styles.imageMask} />
    </div>
  </Container>
);

Support.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  image: PropTypes.shape({}).isRequired,
  title: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const styles = {
  wrapper: {
    margin: '64px auto 32px',
    padding: 0,
    [media.m]: {
      display: 'flex',
    }
  },
  content: {
    padding: '32px',
    [media.m]: {
      flex: '1 1 auto',
      width: '50%',
    }
  },
  imageWrapper: {
    height: '320px',
    position: 'relative',
    width: '100%',
    [media.m]: {
      flex: '1 1 auto',
      height: '35vh',
      minHeight: '380px',
      width: '50%',
    }
  },
  imageMask: {
    [media.m]: {
      backgroundImage: `url(${hexMask})`,
      backgroundSize: 'auto 100%',
      backgroundPosition: 'left center',
      height: '100%',
      position: 'absolute',
      width: '100%'
    }
  },
  image: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '100%',
    position: 'absolute',
    width: '100%'
  }
};

export default Radium(Support);
