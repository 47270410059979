import React from 'react';
import PropTypes from 'prop-types';

const WIDTH_RATIO = 1.57;
const GT_BLACK = '#000';
const GT_RED = '#E30613';

const Logo = ({ color, copyright, size }) => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 254.4 160.6" height={size} width={size * WIDTH_RATIO}>
    {!color &&
      <path fill={GT_RED} d="M3.2,87.3c0,8.6,7,15.6,15.6,15.6h15.6h68.5V37.5H37.5v31.1h34.3v3.1H34.4V34.4h68.5V3.2H34.4H18.8 c-8.6,0-15.6,7-15.6,15.6v15.6v37.4V87.3L3.2,87.3z" />
    }
    <g fill={color || GT_RED}>
      <path d="M109.1,102.8v-28h20.3c4.8,0,7.6,0.1,8.6,0.2c0.9,0.1,1.9,0.4,2.7,1c1,0.6,1.8,1.5,2.2,2.7c0.4,1.1,0.6,2.9,0.6,5.3c0,2-0.1,3.5-0.4,4.5c-0.2,1-0.7,1.7-1.4,2.3c-0.7,0.6-1.9,1-3.4,1.3c1.8,0.1,3,0.7,3.8,1.5c0.4,0.5,0.7,1.2,0.8,1.9c0.1,0.8,0.2,2.4,0.2,4.8v2.6h-8.3v-1.5c0-1.6-0.1-2.7-0.2-3.3c-0.1-0.7-0.3-1.2-0.6-1.5c-0.3-0.3-0.7-0.5-1.3-0.6c-0.5-0.1-1.7-0.1-3.4-0.1h-11.9v7.1H109.1L109.1,102.8z M117.5,88.7h11.8c2.1,0,3.3-0.1,3.7-0.1c0.4,0,0.7-0.1,1-0.3c0.4-0.2,0.6-0.6,0.8-1c0.1-0.5,0.2-1.2,0.2-2.1c0-0.9-0.1-1.6-0.3-2.1c-0.2-0.4-0.5-0.8-0.9-1c-0.3-0.1-0.6-0.2-1.1-0.2c-0.4,0-1.6,0-3.5-0.1h-11.8V88.7L117.5,88.7z" />
      <path d="M176.4,102.8L174,98h-18.3l-2.4,4.7h-9.6l14.6-28h13.1l14.4,28H176.4L176.4,102.8z M171,91.9l-5.3-10.6H164l-5.3,10.6H171L171,91.9z" />
      <polygon points="177.1,74.8 186.3,74.8 196.4,95.7 198,95.7 207.9,74.8 217.1,74.8 203.6,102.8 190.7,102.8 177.1,74.8" />
      <path d="M234.9,74.4c6,0,10.1,0.1,12.3,0.4c3.2,0.4,5.2,2,5.9,4.7c0.5,1.8,0.7,4.9,0.7,9.3c0,4.4-0.2,7.5-0.7,9.3c-0.7,2.7-2.7,4.2-5.9,4.7c-2.2,0.3-6.3,0.4-12.2,0.4c-6,0-10.1-0.1-12.4-0.4c-3.2-0.4-5.2-2-5.9-4.7c-0.5-1.8-0.7-5-0.7-9.5c0-4.3,0.2-7.3,0.7-9.1c0.7-2.7,2.7-4.2,5.9-4.7C224.8,74.5,228.9,74.4,234.9,74.4L234.9,74.4z M234.9,81.7c-4.5,0-7.1,0.1-8,0.4c-1,0.3-1.7,0.9-2,1.8c-0.3,1-0.5,2.8-0.5,5.7c0,1.7,0.1,2.9,0.3,3.7c0.3,1.4,1.4,2.2,3.1,2.4c1.3,0.2,3.7,0.2,7.3,0.2c3.4,0,5.6-0.1,6.8-0.2c1.1-0.1,1.9-0.4,2.5-0.8c0.5-0.4,0.8-0.9,1-1.7c0.2-0.7,0.3-2,0.3-3.7c0-2-0.1-3.5-0.2-4.4c-0.1-0.9-0.4-1.7-0.7-2.1c-0.4-0.6-1.2-1-2.2-1.1C241.3,81.7,238.8,81.7,234.9,81.7L234.9,81.7z" />
    </g>
    <g fill={color || GT_BLACK}>
      <path d="M106,34.4V0.1H0.1V106h68.5v31.1l18.7,23.4l18.7-23.4v-34.3V71.7h34.3V34.4H106z M102.9,34.4H34.4v37.4h37.4v-3.1H37.5V37.5h65.4v65.4H34.4H18.8c-8.6,0-15.6-7-15.6-15.6V71.7V34.4V18.8c0-8.6,7-15.6,15.6-15.6h15.6h68.5V34.4z" />
      <polygon points="109.3,109.2 142.8,109.2 142.8,116.3 130.5,116.3 130.5,137.1 121.6,137.1 121.6,116.3 109.3,116.3 109.3,109.2" />
      <polygon points="146.3,109.2 176.9,109.2 176.9,115.8 154.6,115.8 154.6,119.8 175.8,119.8 175.8,126 154.6,126 154.6,130.4 177.1,130.4 177.1,137.1 146.3,137.1 146.3,109.2" />
      <path d="M206,126.4h8.4c0,1.4,0.1,2.2,0.1,2.6c0,2.2-0.3,3.8-1,5.1c-0.7,1.4-2.1,2.3-4.1,2.8c-2,0.5-5.6,0.7-10.6,0.7c-5.3,0-9-0.1-11.3-0.3c-2.2-0.2-3.9-0.6-5.1-1.2c-1-0.5-1.8-1.3-2.3-2.2c-0.5-0.9-0.8-2.1-1-3.7c-0.1-1.3-0.2-3.6-0.2-6.9c0-3.2,0.1-5.6,0.2-7c0.1-1.4,0.4-2.5,0.7-3.3c0.8-1.8,2.3-2.9,4.7-3.5c2.4-0.5,7.1-0.8,14.2-0.8c3.6,0,6.2,0.1,7.8,0.2c1.6,0.2,2.9,0.5,3.9,0.9c1.3,0.6,2.1,1.5,2.7,2.7c0.5,1.2,0.8,2.9,0.8,5.2c0,0.1,0,0.6,0,1.4h-8.4c0-0.9-0.1-1.6-0.2-1.9c-0.1-0.3-0.2-0.6-0.5-0.8c-0.3-0.3-1.1-0.5-2.5-0.6c-1.3-0.1-3.3-0.2-6-0.2c-2.6,0-4.5,0.1-5.5,0.2c-1,0.1-1.7,0.3-2.2,0.7c-0.5,0.4-0.8,1.1-1,2.1c-0.2,1-0.3,2.5-0.3,4.6c0,2.1,0.1,3.6,0.3,4.5c0.2,0.9,0.5,1.5,1,1.9c0.5,0.4,1.2,0.6,2.3,0.7c1,0.1,2.9,0.2,5.5,0.2c3.2,0,5.4-0.1,6.5-0.2c1.1-0.1,1.8-0.3,2.2-0.7C205.7,129.2,206,128.1,206,126.4L206,126.4z" />
      <polygon points="217.7,109.2 226.6,109.2 226.6,119.2 245.6,119.2 245.6,109.2 254.4,109.2 254.4,137.1 245.6,137.1 245.6,127 226.6,127 226.6,137.1 217.7,137.1 217.7,109.2" />
    </g>
    {copyright &&
      <path fill={color || GT_BLACK} d="M60.8,116.7h5v4.1h-5V116.7L60.8,116.7z M51.1,112.6c-1.4,0-2.3,0.1-2.8,0.3c-0.3,0.2-0.6,0.4-0.7,0.8c-0.1,0.4-0.2,0.9-0.2,1.6c0,0.7,0.1,1.2,0.2,1.5c0.1,0.3,0.4,0.5,0.7,0.7c0.5,0.2,1.5,0.4,2.8,0.4c1.6,0,2.6-0.2,3-0.5c0.4-0.3,0.7-1.1,0.7-2.2c0-0.7-0.1-1.3-0.2-1.6c-0.1-0.3-0.4-0.6-0.7-0.7C53.3,112.7,52.4,112.6,51.1,112.6L51.1,112.6zM51.1,109.5c1.9,0,3.3,0.1,4.2,0.2c0.9,0.1,1.7,0.4,2.2,0.8c0.5,0.4,0.9,0.9,1.1,1.5c0.2,0.6,0.3,1.6,0.3,3c0,1,0,1.8-0.1,2.3c-0.1,0.5-0.2,1-0.3,1.4c-0.4,1-1.2,1.7-2.4,2c-1,0.2-2.7,0.4-5,0.4c-2.1,0-3.5-0.1-4.4-0.2c-0.9-0.1-1.6-0.4-2.1-0.9c-0.5-0.4-0.8-1-1-1.6c-0.2-0.7-0.3-1.8-0.3-3.4c0-0.9,0-1.6,0.1-2.1c0.1-0.5,0.2-0.9,0.4-1.3c0.4-0.8,1.1-1.3,2.1-1.6C46.9,109.7,48.6,109.5,51.1,109.5L51.1,109.5z M36.4,116.7h5v4.1h-5V116.7L36.4,116.7z M24.6,109.8h3.9v1.7h0.2c0.4-0.7,0.9-1.2,1.6-1.5c0.6-0.3,1.6-0.4,2.7-0.4c1.7,0,2.8,0.3,3.5,1c0.7,0.7,1,1.8,1,3.5c0,0.1,0,0.3,0,0.7h-3.9c0-0.6-0.1-1.1-0.2-1.4c-0.3-0.5-0.9-0.7-2-0.7c-1,0-1.7,0.3-2.1,0.8c-0.2,0.3-0.3,0.6-0.4,1c-0.1,0.4-0.1,1-0.1,1.8v4.6h-4.1V109.8L24.6,109.8z M17.4,116.7h5v4.1h-5V116.7L17.4,116.7z M0.3,117.3h4.2c0,0.5,0.3,0.8,0.8,1c0.5,0.2,1.4,0.3,2.7,0.3c1.3,0,2.2-0.1,2.7-0.2c0.5-0.1,0.7-0.4,0.7-0.8c0-0.4-0.2-0.6-0.7-0.8c-0.2-0.1-1.4-0.1-3.5-0.1c-2,0-3.2,0-3.7-0.1c-1.3-0.2-2.2-0.6-2.6-1.3c-0.3-0.5-0.5-1.2-0.5-2c0-1.6,0.6-2.7,1.8-3.2c0.9-0.4,2.8-0.6,5.8-0.6c1.9,0,3.2,0.1,4.1,0.2c0.8,0.1,1.5,0.4,2,0.8c0.3,0.2,0.5,0.5,0.6,0.9c0.1,0.3,0.2,0.8,0.3,1.5h-4.2c-0.1-0.3-0.2-0.4-0.3-0.5c-0.3-0.2-1.2-0.3-2.6-0.3c-1.3,0-2.2,0.1-2.6,0.2c-0.5,0.1-0.7,0.4-0.7,0.7c0,0.4,0.3,0.7,1,0.7c0.5,0,2.4,0.1,5.6,0.1c1.5,0,2.7,0.3,3.3,0.8c0.7,0.5,1,1.4,1,2.6c0,0.8-0.1,1.5-0.4,2c-0.3,0.5-0.7,0.9-1.3,1.2c-1,0.4-3,0.7-6.2,0.7c-2,0-3.4-0.1-4.3-0.2c-0.9-0.2-1.6-0.5-2.1-0.9C0.6,119.4,0.3,118.5,0.3,117.3L0.3,117.3z" />
    }
  </svg>
);

Logo.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  copyright: PropTypes.bool,
  size: PropTypes.number
};

Logo.defaultProps = {
  color: null,
  copyright: false,
  size: 32
};

export default Logo;
