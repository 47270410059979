import { RichText } from 'prismic-reactjs';
import * as R from 'ramda';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from '../routes';
import Link from './Link.react';
import Menu from './Menu.react';
import listenWindowResize, { Device } from '../listenWindowResize.react';
import styled from 'styled-components';

const Item = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  padding: 14px 24px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const MenuLink = styled(Link)`
  color: #FFF;
  display: inline-block;
  font-weight: bold;
  max-width: 240px;
  padding: 12px;
  text-decoration: none;
`;

const MenuLinkDescription = styled.div`
  font-size: 0.85em;
  font-weight: normal;
  margin-top: 12px;
  padding-right: 12px;
  white-space: normal;
`;

class NavigationItem extends Component {
  state = {
    isHovered: false,
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { categories, category: categoryId } = this.props;
    const { isHovered } = this.state;

    if (!categories.length) return null;

    const category = R.find(R.propEq('id', categoryId))(categories);
    const subcategories = categories.filter(c => c.data.parent.id === category.id);

    return (
      <Item
        key={categoryId}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <StyledLink to={`${routes.category}/${category.uid}`}>
          {RichText.asText(category.data.name)}
        </StyledLink>
        {subcategories.length > 0 &&
          <Menu isOpen={isHovered}>
            {subcategories.map(subCategory =>
              <MenuLink key={subCategory.id} to={`${routes.category}/${subCategory.uid}`}>
                {RichText.asText(subCategory.data.name)}
                <MenuLinkDescription>
                  {RichText.asText(subCategory.data.description)}
                </MenuLinkDescription>
              </MenuLink>
            )}
          </Menu>
        }
      </Item>
    );
  }
}

NavigationItem.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  category: PropTypes.string.isRequired,
  device: PropTypes.instanceOf(Device).isRequired,
};

const mapStateToProps = state => ({
  categories: state.content.categories,
});

export default connect(
  mapStateToProps,
  null
)(listenWindowResize(NavigationItem));
