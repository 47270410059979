import Radium from "radium"
import React from "react"
import PropTypes from 'prop-types';
import Heading from "../components/Heading.react"
import Image from "../components/Image.react"
import Link from "../components/Link.react"
import Container from "../Feature/Layout/Container.react"
import theme, { media } from "../styleConstants"
import routes from "../routes"
import isoImg from "../assets/images/iso_9001_14001.png"
import TeamViewerLink from "./FooterTeamViewerLink.react"

const MenuLink = props => (
  <Link style={styles.link} {...props}>
    {props.children}
  </Link>
)
MenuLink.propTypes = { children: PropTypes.string.isRequired }

const Footer = () => (
  <div style={styles.wrapper}>
    <div style={styles.menu}>
      <Container style={styles.container}>
        <div style={styles.colA}>
          <Heading level={4} style={{ color: "white" }}>
            Gravo Tech s.r.o.
          </Heading>
          <MenuLink to={routes.contacts}>Kontakt</MenuLink>
          <MenuLink to={routes.about}>O nás</MenuLink>
          <MenuLink to={routes.support}>Podpora</MenuLink>
          {/* <MenuLink to={routes.certificates}>Certifikace</MenuLink> */}
          <MenuLink to={routes.legal}>Obchodni podminky</MenuLink>
          <MenuLink to={routes.bazaar}>Bazar</MenuLink>
          {/* <MenuLink to={routes.contacts}>Historie firmy</MenuLink> */}
        </div>

        <div style={styles.colB}>
          <Heading level={4} style={{ color: "white" }}>
            Vzdálená podpora online
          </Heading>
          <TeamViewerLink
            title="Miroslav Halík"
            link="https://get.teamviewer.com/gravotech"
          />
          <TeamViewerLink
            title="Miroslav Mišútka"
            link="https://get.teamviewer.com/misutka"
          />
        </div>

        <div style={styles.colC}>
          <Image src={isoImg} style={{ height: "80px", width: "80px" }} />
        </div>
      </Container>
    </div>
    <div style={styles.bar}>
      <Container>Gravotech &copy; 2024</Container>
    </div>
  </div>
)

const styles = {
  wrapper: {
    background: theme.primary,
    color: "white",
    fontSize: ".9em",
    marginTop: "32px",
  },
  menu: {
    borderColor: "#4f6970",
    borderStyle: "solid",
    borderWidth: "2px 0",
    padding: "24px 0",
  },
  container: {
    [media.m]: {
      display: "flex",
    },
  },
  colA: {
    [media.m]: {
      flex: "1 1 auto",
      minWidth: "25%",
    },
  },
  colB: {
    textAlign: "center",
    [media.m]: {
      flex: "1 1 auto",
      minWidth: "50%",
    },
  },
  colC: {
    [media.m]: {
      flex: "1 1 auto",
      minWidth: "25%",
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  bar: {
    padding: "24px 0",
  },
  link: {
    color: "white",
    display: "block",
    margin: ".25em 0",
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline",
    },
  },
}

export default Radium(Footer)
