import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button.react';
import Heading from '../components/Heading.react';
import Select from '../components/form/Select.react';
import routes from '../routes';

const Searcher = ({ materials, sizes, material, size }) => {
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    navigate(`${routes.search}/${material}/${size}`);
  };

  return (
    <Form onSubmit={onSubmit}>
      <div style={styles.wrapper}>
        <Heading level={3} color="white">Najděte nejvhodnější řešení pro:</Heading>
        <Field defaultValue="all" name="size" component={Select}>
          <option value="all">Velikost gravírované plochy</option>
          {sizes.length > 0 &&
            sizes.map(size =>
              <option key={size.id} value={size.uid}>{size.data.title}</option>
            )
          }
        </Field>
        <Field defaultValue="all" name="material" component={Select}>
          <option value="all">Materiál pro gravírování</option>
          {materials.length > 0 &&
            materials.map(material =>
              <option key={material.id} value={material.uid}>{material.data.title}</option>
            )
          }
        </Field>
        <Button kind="green" type="submit">Vyhledat</Button>
      </div>
    </Form>
  );
};

const styles = {
  wrapper: {
    background: '#031419',
    borderColor: '#4f6970',
    borderStyle: 'solid',
    borderWidth: '0 0 2px',
    color: 'white',
    padding: '32px',
    textAlign: 'center',
  }
};

Searcher.defaultProps = {
  material: '',
  materials: [],
  size: '',
  sizes: [],
};

Searcher.propTypes = {
  material: PropTypes.string,
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  size: PropTypes.string,
  sizes: PropTypes.arrayOf(PropTypes.shape({})),
};

const selector = formValueSelector('searcher');

const mapStateToProps = state => ({
  material: selector(state, 'material') || 'all',
  materials: state.content.materials,
  size: selector(state, 'size') || 'all',
  sizes: state.content.sizes,
});

export default connect(
  mapStateToProps
)(reduxForm({
  form: 'searcher',
  initialValues: { material: 'all', size: 'all' }
})(Searcher));
