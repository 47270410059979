import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Hexagon from '../../components/Hexagon.react';
import Icon from '../../components/Icon.react';
import Link from '../../components/Link.react';
import routes from '../../routes';

const CategoryBack = ({ parent }) => (parent ?
  <li style={styles.wrapper}>
    <Link style={styles.link} to={`${routes.category}/${parent.uid}`}>
      <Hexagon>
        <Icon kind="arrow-back" color="#fff" size={48} style={styles.icon} />
      </Hexagon>
    </Link>
  </li>
: null);

CategoryBack.defaultProps = {
  parent: {},
};

CategoryBack.propTypes = {
  parent: PropTypes.shape({})
};

const styles = {
  wrapper: {
    flex: '0 0 auto',
    alignSelf: 'auto',
    listStyle: 'none',
    marginRight: '2px',
    padding: 0,
    textAlign: 'center',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
  icon: {
    marginTop: '8px',
  }
};

export default Radium(CategoryBack);
