import Color from 'color';
import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Link from './Link.react';
import theme from '../styleConstants';

class Button extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    kind: PropTypes.oneOf(['dark', 'green']),
    onClick: PropTypes.func,
    style: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object)
    ]),
    to: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    kind: 'dark',
    onClick: null,
    style: {},
    to: '',
    disabled: false,
  }

  render() {
    const { children, disabled, kind, onClick, style, to } = this.props;

    const props = {
      onClick,
      disabled,
      style: [
        styles.base,
        styles[kind],
        to && styles.link,
        disabled && styles.disabled,
        style
      ]
    };

    if (to) {
      return <Link {...props} to={to}>{children}</Link>;
    }

    return <button {...props}>{children}</button>;
  }
}

const styles = {
  base: {
    borderWidth: '0 0 2px 0',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '1rem',
    fontWeight: 700,
    padding: '8px 18px',
    textTransform: 'uppercase',
    outline: 'none'
  },
  dark: {
    backgroundColor: theme.primary,
    borderColor: theme.secondary,
    borderStyle: 'solid',
    color: '#fff',
    ':hover': {
      backgroundColor: Color(theme.primary).lighten(1)
    }
  },
  green: {
    backgroundColor: theme.secondary,
    borderColor: theme.secondary,
    borderRadius: '3px',
    color: '#fff',
    ':hover': {
      backgroundColor: Color(theme.secondary).lighten(.1)
    }
  },
  disabled: {
    opacity: 0.5,
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none'
  }
};

export default Radium(Button);
