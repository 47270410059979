import Radium, { Style } from 'radium';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';


class Menu extends Component {
  render() {
    const { children, isOpen } = this.props;

    const items = isOpen ? (<div style={styles.wrapper}>
      <div style={styles.menu}>
        {children}
      </div>
    </div>) :
    null;

    return (
      <div>
        <CSSTransitionGroup
          transitionName="menu"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {items}
        </CSSTransitionGroup>
        <Style
          rules={{
            '.menu-enter': {
              opacity: '0.01',
            },
            '.menu-enter.menu-enter-active': {
              opacity: 1,
              transition: 'opacity 300ms ease-in'
            },
            '.menu-leave': {
              opacity: 1,
            },
            '.menu-leave.menu-leave-active': {
              opacity: 0.01,
              transition: 'opacity 300ms ease-in',
            }
          }}
        />
      </div>
    );
  }
}

Menu.defaultProps = {
  isOpen: false,
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
};

const styles = {
  wrapper: {
    background: 'rgba(9, 173, 20, .9)',
    boxShadow: 'inset 0 0 48px 24px rgb(9, 173, 20), 0 8px 8px rgba(0, 0, 0, .05)',
    left: 0,
    padding: '12px 0 24px',
    position: 'absolute',
    right: 0,
    top: '100%',
    zIndex: 3,
  },
  menu: {
    alignItems: 'top',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    textAlign: 'left'
  },
  link: {
    color: 'white',
  }
};

export default Radium(Menu);
