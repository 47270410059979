import * as prismic from '@prismicio/client';

const apiUrl = "https://gravotech.cdn.prismic.io/api/v2";
const client = prismic.createClient(apiUrl);

export const GET_ABOUT = "GET_ABOUT"
export const GET_ABOUT_START = "GET_ABOUT_START"
export const GET_ABOUT_SUCCESS = "GET_ABOUT_SUCCESS"
export const GET_ABOUT_ERROR = "GET_ABOUT_ERROR"
export const GET_ARTICLES = "GET_ARTICLES"
export const GET_ARTICLES_START = "GET_ARTICLES_START"
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS"
export const GET_ARTICLES_ERROR = "GET_ARTICLES_ERROR"
export const GET_BAZAAR = "GET_BAZAAR"
export const GET_BAZAAR_START = "GET_BAZAAR_START"
export const GET_BAZAAR_SUCCESS = "GET_BAZAAR_SUCCESS"
export const GET_BAZAAR_ERROR = "GET_BAZAAR_ERROR"
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_START = "GET_CATEGORIES_START"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR"
export const GET_FILES = "GET_FILES"
export const GET_FILES_START = "GET_FILES_START"
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS"
export const GET_FILES_ERROR = "GET_FILES_ERROR"
export const GET_CERTIFICATES = "GET_CERTIFICATES"
export const GET_CERTIFICATES_START = "GET_CERTIFICATES_START"
export const GET_CERTIFICATES_SUCCESS = "GET_CERTIFICATES_SUCCESS"
export const GET_CERTIFICATES_ERROR = "GET_CERTIFICATES_ERROR"
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_START = "GET_PRODUCTS_START"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR"
export const GET_CONTACTS = "GET_CONTACTS"
export const GET_CONTACTS_START = "GET_CONTACTS_START"
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS"
export const GET_CONTACTS_ERROR = "GET_CONTACTS_ERROR"
export const GET_GDPR = "GET_GDPR"
export const GET_GDPR_START = "GET_GDPR_START"
export const GET_GDPR_SUCCESS = "GET_GDPR_SUCCESS"
export const GET_GDPR_ERROR = "GET_GDPR_ERROR"
export const GET_HOMEPAGE = "GET_HOMEPAGE"
export const GET_HOMEPAGE_START = "GET_HOMEPAGE_START"
export const GET_HOMEPAGE_SUCCESS = "GET_HOMEPAGE_SUCCESS"
export const GET_HOMEPAGE_ERROR = "GET_HOMEPAGE_ERROR"
export const GET_MATERIALS_PAGE = "GET_MATERIALS_PAGE"
export const GET_MATERIALS_PAGE_START = "GET_MATERIALS_PAGE_START"
export const GET_MATERIALS_PAGE_SUCCESS = "GET_MATERIALS_PAGE_SUCCESS"
export const GET_MATERIALS_PAGE_ERROR = "GET_MATERIALS_PAGE_ERROR"
export const GET_NEWS = "GET_NEWS"
export const GET_NEWS_START = "GET_NEWS_START"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"
export const GET_NEWS_ERROR = "GET_NEWS_ERROR"
export const GET_LEGAL = "GET_LEGAL"
export const GET_LEGAL_START = "GET_LEGAL_START"
export const GET_LEGAL_SUCCESS = "GET_LEGAL_SUCCESS"
export const GET_LEGAL_ERROR = "GET_LEGAL_ERROR"
export const GET_SUPPORT = "GET_SUPPORT"
export const GET_SUPPORT_START = "GET_SUPPORT_START"
export const GET_SUPPORT_SUCCESS = "GET_SUPPORT_SUCCESS"
export const GET_SUPPORT_ERROR = "GET_SUPPORT_ERROR"

export const GET_SIZES = "GET_SIZES"
export const GET_SIZES_START = "GET_SIZES_START"
export const GET_SIZES_SUCCESS = "GET_SIZES_SUCCESS"
export const GET_SIZES_ERROR = "GET_SIZES_ERROR"
export const GET_MATERIALS = "GET_MATERIALS"
export const GET_MATERIALS_START = "GET_MATERIALS_START"
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS"
export const GET_MATERIALS_ERROR = "GET_MATERIALS_ERROR"

export const getSearcherValues = () => dispatch => {
  client.get()
    .then(api => {
      return client.getByType("size", {
        orderings: {
          field: "my.size.title",
        },
      });
    })
    .then(response => {
      dispatch({
        type: GET_SIZES_SUCCESS,
        payload: response.results,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_SIZES_ERROR,
        error: error.message || 'Failed to fetch sizes',
      });
    });

  client.get()
    .then(api => {
      return client.getByType("material", {
        orderings: {
          field: "my.material.title",
        },
      });
    })
    .then(response => {
      dispatch({
        type: GET_MATERIALS_SUCCESS,
        payload: response.results,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_MATERIALS_ERROR,
        error: error.message || 'Failed to fetch materials',
      });
    });
}

export const getArticles = () => dispatch => {
  client.getByType("article", {
    pageSize: 100,
  })
  .then(response => {
    dispatch({
      type: GET_ARTICLES_SUCCESS,
      payload: response.results,
    });
  })
  .catch(error => {
    dispatch({
      type: GET_ARTICLES_ERROR,
      error: error.message || 'Failed to fetch articles',
    });
  });
}

export const getCategories = () => dispatch => {
  client.getByType("category", {
    orderings: {
      field: "my.category.order",
      direction: "desc",
    },
    pageSize: 100,
  })
  .then(response => {
    dispatch({
      type: GET_CATEGORIES_SUCCESS,
      payload: response.results,
    });
  })
  .catch(error => {
    dispatch({
      type: GET_CATEGORIES_ERROR,
      error: error.message || 'Failed to fetch categories',
    });
  });
}

export const getFiles = () => dispatch => {
  client.getByType("files", {
    pageSize: 100,
  })
  .then(response => {
    dispatch({
      type: GET_FILES_SUCCESS,
      payload: response.results,
    });
  })
  .catch(error => {
    dispatch({
      type: GET_FILES_ERROR,
      error: error.message || 'Failed to fetch files',
    });
  });
}

export const getProducts = () => dispatch => {
  client.getByType("product", {
    orderings: {
      field: "my.product.order",
      direction: "desc",
    },
    pageSize: 100,
    fetchLinks: "category.name",
  })
  .then(response => {
    dispatch({
      type: GET_PRODUCTS_SUCCESS,
      payload: response.results,
    });
  })
  .catch(error => {
    dispatch({
      type: GET_PRODUCTS_ERROR,
      error: error.message || 'Failed to fetch products',
    });
  });
}

export const getContacts = () => dispatch => {
  client.getSingle("contacts")
    .then(response => {
      dispatch({
        type: GET_CONTACTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_CONTACTS_ERROR,
        error: error.message || 'Failed to fetch contacts',
      });
    });
}

export const getAbout = () => dispatch => {
  client.getSingle("about")
    .then(response => {
      dispatch({
        type: GET_ABOUT_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ABOUT_ERROR,
        error: error.message || 'Failed to fetch about',
      });
    });
}

export const getGdpr = () => dispatch => {
  client.getSingle("gdpr")
    .then(response => {
      dispatch({
        type: GET_GDPR_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_GDPR_ERROR,
        error: error.message || 'Failed to fetch GDPR',
      });
    });
}

export const getHomepage = () => dispatch => {
  client.getSingle("homepage")
    .then(response => {
      dispatch({
        type: GET_HOMEPAGE_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_HOMEPAGE_ERROR,
        error: error.message || 'Failed to fetch homepage',
      });
    });
}

export const getCertificates = () => dispatch => {
  client.getSingle("certificates")
    .then(response => {
      dispatch({
        type: GET_CERTIFICATES_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_CERTIFICATES_ERROR,
        error: error.message || 'Failed to fetch certificates',
      });
    });
}

export const getBazaar = () => dispatch => {
  client.getSingle("bazaar")
    .then(response => {
      dispatch({
        type: GET_BAZAAR_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_BAZAAR_ERROR,
        error: error.message || 'Failed to fetch bazaar',
      });
    });
}

export const getLatestNews = () => dispatch => {
  client.getByType("news", {
    orderings: {
      field: "my.news.published",
      direction: "desc",
    },
    pageSize: 5,
  })
  .then(response => {
    dispatch({
      type: GET_NEWS_SUCCESS,
      payload: response.results,
    });
  })
  .catch(error => {
    dispatch({
      type: GET_NEWS_ERROR,
      error: error.message || 'Failed to fetch latest news',
    });
  });
}

export const getLegal = () => dispatch => {
  client.getSingle("legal")
    .then(response => {
      dispatch({
        type: GET_LEGAL_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_LEGAL_ERROR,
        error: error.message || 'Failed to fetch legal',
      });
    });
}

export const getSupport = () => dispatch => {
  client.getSingle("support")
    .then(response => {
      dispatch({
        type: GET_SUPPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_SUPPORT_ERROR,
        error: error.message || 'Failed to fetch support',
      });
    });
}

export const getMaterialsPage = () => dispatch => {
  client.getSingle("materials")
    .then(response => {
      dispatch({
        type: GET_MATERIALS_PAGE_SUCCESS,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_MATERIALS_PAGE_ERROR,
        error: error.message || 'Failed to fetch materials page'
      });
    });
}

export const getMaterials = () => dispatch => {
  client.getByType("material", {
    orderings: {
      field: "my.material.title",
    },
  })
  .then(response => {
    dispatch({
      type: GET_MATERIALS_SUCCESS,
      payload: response.results,
    });
  })
  .catch(error => {
    dispatch({
      type: GET_MATERIALS_ERROR,
      error: error.message || 'Failed to fetch materials',
    });
  });
}
