import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Heading from './Heading.react';
import { colors } from '../styleConstants';

const Products = props => (
  <div>
    <Heading level={3} style={styles.heading}>Podobné produkty</Heading>
    <ul style={styles.wrapper}>
      {props.children}
    </ul>
  </div>
);

Products.propTypes = {
  children: PropTypes.node
};

Products.defaultProps = {
  children: null
};

const styles = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    padding: 0,
    listStyle: 'none'
  },
  heading: {
    borderBottom: `1px solid ${colors.green}`,
    textAlign: 'center',
    padding: '12px 0'
  }
};

export default Radium(Products);
