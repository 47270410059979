import Color from 'color';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../components/Heading.react';
import Image from '../components/Image.react';
import Link from '../components/Link.react';
import theme from '../styleConstants';
import teamViewerImg from '../assets/images/teamviewer-icon.png';

const FooterTeamViewerLink = ({ link, title }) => (
  <Link to={link} style={styles.wrapper} target="_blank">
    <Image src={teamViewerImg} style={{ height: '28px' }} />
    <Heading level={4}>{title}</Heading>
  </Link>
);

FooterTeamViewerLink.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const styles = {
  wrapper: {
    background: theme.primary,
    borderRadius: '4px',
    color: 'white',
    display: 'inline-block',
    fontSize: '.9em',
    margin: '0 4px',
    padding: '12px 16px',
    textAlign: 'center',
    textDecoration: 'none',
    ':hover': {
      background: Color(theme.primary).lighten(1)
    }
  },
};

export default Radium(FooterTeamViewerLink);
