import { RichText } from 'prismic-reactjs';
import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import * as contentActions from '../../common/actions/content';
import Breadcrumbs from '../../components/Breadcrumbs.react';
import Heading from '../../components/Heading.react';
import Loader from '../../components/Loader.react';
import Teamviewer from '../../components/Teamviewer.react';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';
import hexagonsBg from '../../assets/images/hexagons_outlined.jpg';
import { media } from '../../styleConstants';

class Support extends Component {
  componentDidMount() {
    const { getSupport } = this.props;

    getSupport();
  }

  render() {
    const { support } = this.props;

    return (
      <Layout>
        <div style={styles.wrapper}>
          <Breadcrumbs />
          <Container>
            {Object.keys(support).length ?
              <div>
                <Helmet title={RichText.asText(support.title)} />
                <Heading>{RichText.asText(support.title)}</Heading>
                <div style={styles.hero}>
                  <div style={styles.contentWrapper}>
                    {RichText.render(support.content)}
                  </div>
                  <div style={styles.teamWrapper}>
                    <div style={styles.teamContent}>
                      <Heading level={4} style={{ color: '#2F6BCF' }}>Podpora online</Heading>
                      <div style={styles.teamItem}>
                        <Teamviewer title="Miroslav Halík" link="https://get.teamviewer.com/gravotech" />
                      </div>
                      <div style={styles.teamItem}>
                        <Teamviewer title="Miroslav Mišútka" link="https://get.teamviewer.com/misutka" />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={styles.blocks}>
                  <div style={styles.block}>
                    {RichText.render(support.content1)}
                  </div>
                  <div style={styles.block}>
                    {RichText.render(support.content2)}
                  </div>
                  <div style={styles.block}>
                    {RichText.render(support.content3)}
                  </div>
                  <div style={styles.block}>
                    {RichText.render(support.content4)}
                  </div>
                </div>
              </div> :
              <Loader />
            }
          </Container>
        </div>
      </Layout>
    );
  }
}

Support.defaultProps = {
  support: {},
};

Support.propTypes = {
  getSupport: PropTypes.func.isRequired,
  support: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  support: state.content.support,
});

const mapDispatchToProps = dispatch => ({
  getSupport: () => dispatch(contentActions.getSupport()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Radium(Support));

const styles = {
  wrapper: {
    backgroundImage: `url(${hexagonsBg})`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '66% auto',
    margin: 0,
    padding: '24px 0',
    width: '100%'
  },
  hero: {
    [media.m]: {
      display: 'flex',
    },
  },
  contentWrapper: {
    flex: 1,
  },
  teamWrapper: {
    flex: 0,
    justifyContent: 'center',
    padding: '0 32px',
  },
  teamContent: {
    background: '#eee',
    borderRadius: '8px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, .05)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    padding: '8px',
  },
  teamItem: {
    padding: '8px',
  },
  blocks: {
    [media.m]: {
      display: 'flex',
    },
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '32px -32px'
  },
  block: {
    flexBasis: '50%',
    padding: '16px 32px',
    width: '50%'
  }
};
