import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../styleConstants';

const Select = ({ children, input }) => (
  <select style={styles} {...input}>
    {children}
  </select>
);

Select.propTypes = {
  children: PropTypes.node.isRequired,
  input: PropTypes.shape({}).isRequired,
};

const styles = {
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  appearance: 'none',
  background: 'white',
  borderRadius: '3px',
  borderWidth: 0,
  color: colors.blueDark,
  cursor: 'pointer',
  fontSize: '.75rem',
  fontWeight: 700,
  padding: '10px 20px',
  marginRight: '4px',
};

export default Radium(Select);
