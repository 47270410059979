import moment from 'moment';
import { Date, RichText } from 'prismic-reactjs';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from '../components/Link.react';
import Loader from '../components/Loader.react';
import ContentProvider from '../ContentProvider.react';
import routes from '../routes';
import listenWindowResize, { Device } from '../listenWindowResize.react';
import theme from '../styleConstants';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const StyledLink = styled(Link)`
  color: ${theme.secondary};
`;

const Menu = styled.div`
  background: white;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  margin-top: -4px;
  max-height: 280px;
  overflow-y: auto;
  padding: 4px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 100%;
  width: 240px;
  z-index: 9;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

class News extends Component {
  state = {
    isHovered: false,
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { device, news, style } = this.props;
    const { isHovered } = this.state;

    return (
      <Wrapper key="newsLink" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <StyledLink style={style} to={routes.news}>
          Novinky
          <div style={styles.badge} />
        </StyledLink>
        {device.atLeast('m') &&
          <ContentProvider fetch={['news']}>
            <Menu isVisible={isHovered}>
              {news.length ?
                news.map(n =>
                  <div key={n.uid} style={styles.item}>
                    <div style={styles.date}>{moment(Date(n.data.published)).format('dddd, Do MMMM YYYY')}</div>
                    <div style={styles.heading}>{RichText.asText(n.data.title)}</div>
                    <div style={styles.content}>{RichText.render(n.data.content)}</div>
                  </div>
                ) :
                <Loader size={48} />
              }
            </Menu>
          </ContentProvider>
        }
      </Wrapper>
    );
  }
}

News.defaultProps = {
  style: {}
};

News.propTypes = {
  device: PropTypes.instanceOf(Device).isRequired,
  style: PropTypes.oneOf([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ]),
};

const styles = {
  wrapper: {
    display: 'inline-block',
    position: 'relative',
    ':hover': {},
  },
  link: {
    color: theme.secondary,
  },
  menu: {
    background: 'white',
    borderRadius: '2px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, .15)',
    marginTop: '-4px',
    maxHeight: '280px',
    overflowY: 'auto',
    padding: '4px',
    position: 'absolute',
    right: 0,
    textAlign: 'left',
    top: '100%',
    width: '240px',
    zIndex: 9,
  },
  badge: {
    backgroundColor: 'red',
    borderRadius: '4px',
    height: '8px',
    position: 'absolute',
    right: '-4px',
    top: 0,
    width: '8px'
  },
  item: {
    borderBottom: `1px solid ${theme.secondary}`,
    color: theme.primary,
    marginBottom: '4px',
    padding: '8px',
  },
  itemLast: {
    borderBottom: 0
  },
  date: {
    fontSize: '10px',
    fontWeight: 700,
  },
  heading: {
    fontSize: '16px',
    fontWeight: 300,
    marginTop: '2px',
  },
  content: {
    color: '#777',
    fontSize: '11px',
  },
};

News.defaultProps = {
  news: [],
};

News.propTypes = {
  news: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  news: state.content.news,
});

export default connect(mapStateToProps)(listenWindowResize(News));
