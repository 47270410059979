import Color from "color"
import { Style, StyleRoot } from "radium"
import React from "react"
import CookieConsent from "react-cookie-consent"
import Helmet from "react-helmet"
import { Route, Routes } from "react-router-dom"
import routes from "./routes"
import Homepage from "./Home/Page.react"
import About from "./Feature/About/Page.react"
import Bazaar from "./Feature/Bazaar/Page.react"
import Blog from "./Feature/Blog/Page.react"
import Category from "./Feature/Category/Page.react"
import Certificates from "./Feature/Certificates/Page"
import Contacts from "./Feature/Contacts/Page.react"
import Files from "./Feature/Files/Page.react"
import Gdpr from "./Feature/Gdpr/Page.react"
import Legal from "./Feature/Legal/Page.react"
import Materials from "./Feature/Materials/Page.react"
import News from "./Feature/News/Page.react"
import { ProductPage}  from "./Feature/Product/Page.react"
import Search from "./Feature/Search/Page.react"
import Support from "./Feature/Support/Page.react"
import NotFound from "./NotFound"
import ContentProvider from "./ContentProvider.react"
import ScrollToTop from "./ScrollToTop.react"
import { colors } from "./styleConstants"
import Heading from "./components/Heading.react"
import Link from "./components/Link.react"

import "./assets/css/fonts.css"

import favicon16 from "./assets/favicons/favicon-16x16.png"
import applefavicon57 from "./assets/favicons/apple-icon-57x57.png"

const App = () => {
  return (
  <StyleRoot>
    <ContentProvider
      fetch={["categories", "homepage", "products", "searcher", "certificates"]}
    >
      <ScrollToTop>
        <Routes>
          <Route path={routes.home} element={<Homepage />} />
          <Route path={`${routes.category}/:categoryId`} element={<Category />} />
          <Route path={routes.about} element={<About />} />
          <Route path={routes.bazaar} element={<Bazaar />} />
          <Route path={routes.contacts} element={<Contacts />} />
          <Route path={routes.certificates} element={<Certificates />} />
          <Route path={routes.blog} element={<Blog />} />
          <Route path={routes.files} element={<Files />} />
          <Route path={routes.gdpr} element={<Gdpr />} />
          <Route path={routes.legal} element={<Legal />} />
          <Route path={routes.materials} element={<Materials />} />
          <Route path={routes.news} element={<News />} />
          <Route path={`${routes.product}/:productId`} element={<ProductPage />} />
          <Route path={`${routes.search}/:material/:size`} element={<Search />} />
          <Route path={routes.support} element={<Support />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
      <Helmet
        title="Gravotech"
        link={[
          {
            rel: "icon",
            type: "image/png",
            href: favicon16,
          },
          {
            rel: "apple-touch-icon",
            sizes: "57x57",
            href: applefavicon57,
          },
        ]}
      />
      <Style
        rules={{
          "*": {
            boxSizing: "border-box",
          },
          body: {
            margin: 0,
            padding: 0,
            fontFamily:
              "Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif, sans-serif",
            fontWeight: 300,
            fontSize: "91.5%",
          },
          p: {
            marginBottom: "1em",
            marginTop: 0,
          },
        }}
      />
    </ContentProvider>
    <CookieConsent
      buttonStyle={styles.cookieConsentButton}
      buttonText="Beru na vědomí"
      style={styles.cookieConsent}
    >
      <Heading level={3}>Informace o používání cookies</Heading>
      Tyto stránky používají cookies, abychom Vás odlišili od jiných uživatelů
      webových stránek. Pokud budete pokračovat v prohlížení stránek, souhlasíte
      s používáním souborů cookies. &nbsp;
      <Link style={styles.cookieConsentLink} to={routes.gdpr}>
        Více informací
      </Link>
    </CookieConsent>
    </StyleRoot>
  )
}

const styles = {
  cookieConsent: {
    backgroundColor: Color(colors.green).alpha(0.95),
    color: colors.blueDark,
  },
  cookieConsentButton: {
    backgroundColor: colors.blueDark,
    border: 0,
    color: "#fff",
    fontSize: "16px",
  },
  cookieConsentLink: {
    color: colors.blueDark,
  },
}

export default App
