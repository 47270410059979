import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Link from '../components/Link.react';
import routes from '../routes';
import Logo from './Logo.react';
import Navigation from './Navigation.react';
import NewsLink from './News.react';
import listenWindowResize, { Device } from '../listenWindowResize.react';
import { media } from '../styleConstants';

const Header = ({ device }) => (
  <div style={styles.wrapper}>
    <div style={styles.header}>
      <div style={styles.heading}>
        <Link to={routes.home}>
          <Logo color="#fff" size={device.atLeast('l') ? 42 : 28} />
        </Link>
      </div>
      <div style={styles.menu}>
        <Link style={styles.link} to={routes.support}>Podpora</Link>
        {/* <Link style={styles.link} to={routes.blog}>Blog</Link> */}
        <NewsLink style={styles.link} />
        <Link style={styles.link} to={routes.contacts}>Kontakt</Link>
      </div>
    </div>
    <Navigation />
  </div>
);

Header.propTypes = {
  device: PropTypes.instanceOf(Device).isRequired,
};

const styles = {
  wrapper: {
    background: '#031419',
    borderBottom: '2px solid #09AD14',
    boxShadow: '0 0 48px rgba(0, 0, 0, .33)',
    zIndex: 1,
  },
  header: {
    display: 'flex',
    height: '38px',
  },
  heading: {
    flex: '1 0 auto',
    padding: '5px 0 0 24px',
    zIndex: 1,
    [media.md]: {
      padding: '20px 0 0 24px',
    }
  },
  menu: {
    flex: '1 0 auto',
    padding: '0 12px',
    textAlign: 'right'
  },
  link: {
    color: '#fff',
    display: 'inline-block',
    fontSize: '11px',
    fontWeight: 'bold',
    position: 'relative',
    margin: '12px 16px',
    textDecoration: 'none'
  },
  news: {
    color: '#09AD14'
  }
};

export default listenWindowResize(Radium(Header));
