import * as actions from '../actions/mailer';

const initialState = {
  requestState: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CLEAR_REQUEST:
      return { ...state, requestState: '' };

    case actions.SEND_REQUEST_START:
      return { ...state, requestState: 'start' };

    case actions.SEND_REQUEST_ERROR:
      return { ...state, requestState: 'error' };

    case actions.SEND_REQUEST_SUCCESS:
      return { ...state, requestState: 'success' };

    default:
      return state;
  }
};

export default reducer;
