import { RichText } from 'prismic-reactjs';
import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Heading from '../../components/Heading.react';
import Loader from '../../components/Loader.react';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';
import Header from './Header.react';
import Product from '../../components/ProductItem.react';
import Products from '../../components/ProductsList.react';
import Subcategories from './Subcategories.react';
import Subcategory from './Subcategory.react';
import CategoryBack from './CategoryBack.react';

const Category = ({ categories, products }) => {
  const { categoryId } = useParams();

  if (!categories.length) return <Loader />;
  if (!products.length) return <Loader />;

  const category = R.find(R.propEq('uid', categoryId))(categories);

  if (!category) {
    return (
      <Layout>
        <Header>
          <Heading>Kategorie nenalezena</Heading>
          Požadovaná kategorie nebyla nalezena, zkontrolujte adresu
        </Header>
      </Layout>
    );
  }

  return (
    <Layout>
      <Helmet title={RichText.asText(category.data.name)} />
      <Header>
        <Heading>{RichText.asText(category.data.name)}</Heading>
        {RichText.render(category.data.description)}
        <Subcategories>
          {category.data.parent.uid && <CategoryBack parent={category.data.parent} />}
          {categories
            .filter(c => c.data.parent.id === category.id)
            .map(subCategory => <Subcategory key={subCategory.uid} category={subCategory} />)
          }
        </Subcategories>
      </Header>
      <Container>
        <Products>
          {products
            .filter(product => R.contains(category.id, product.data.categories.map(({ category: c }) => c.id)))
            .map(product => <Product key={product.id} product={product} />)
          }
        </Products>
      </Container>
    </Layout>
  );
};

Category.defaultProps = {
  categories: {},
  products: {},
};

Category.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  categories: state.content.categories,
  products: state.content.products,
});

export default connect(
  mapStateToProps,
  null
)(Category);
