import { RichText } from 'prismic-reactjs';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Button from '../../components/Button.react';
import Heading from '../../components/Heading.react';
import Icon from '../../components/Icon.react';
import Loader from '../../components/Loader.react';
import ContentProvider from '../../ContentProvider.react';
import theme from '../../styleConstants';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';
import Header from '../Category/Header.react';

class Materials extends Component {
  render() {
    const { materials } = this.props;

    return (
      <ContentProvider fetch={['materials']}>
        {Object.keys(materials).length ?
          <Layout>
            <Helmet title="Materiály" />
            <Header>
              <Heading>{RichText.asText(materials.title)}</Heading>
              {RichText.render(materials.perex)}
            </Header>
            <Container>
              <div style={styles.files}>
                {materials.documents.length &&
                  materials.documents.map(doc =>
                    <div key={RichText.asText(doc.name)} style={styles.item}>
                      <Icon color={theme.secondary} kind="pdf" size={64} style={styles.icon} />
                      <h2 style={styles.heading}>{RichText.asText(doc.name)}</h2>
                      <div style={styles.description}>{RichText.render(doc.description)}</div>
                      <a href={doc.file.url} target="_blank" rel="noopener noreferrer">
                        <Button style={styles.button}>stáhnout</Button>
                      </a>
                    </div>
                  )
                }
              </div>
            </Container>
          </Layout>
          :
          <Loader />
        }
      </ContentProvider>
    );
  }
}

const styles = {
  files: {},
  item: {
    borderBottom: `1px solid ${theme.secondary}`,
    minHeight: '116px',
    position: 'relative',
    padding: '20px 170px 16px 80px'
  },
  icon: {
    left: 0,
    position: 'absolute',
    top: '22px'
  },
  button: {
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    right: 0
  },
  heading: {
    margin: 0
  }
};

Materials.defaultProps = {
  materials: {},
};

Materials.propTypes = {
  materials: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  materials: state.content.materialsPage,
});

export default connect(
  mapStateToProps
)(Materials);
