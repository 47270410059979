import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Bubble from '../../components/Bubble.react';
import Footer from '../../components/Footer.react';
import Header from '../../components/Header.react';
import listenWindowResize, { Device } from '../../listenWindowResize.react';

const BasicLayout = ({ device, children }) => (
  <div style={styles.page}>
    <Helmet title="Gravotech" />
    <div style={styles.header}>
      <Header />
    </div>
    <div style={styles.content}>
      {children}
    </div>
    <div style={styles.footer}>
      <Footer />
    </div>
    {device.atLeast('md') && <Bubble />}
  </div>
);

BasicLayout.propTypes = {
  device: PropTypes.instanceOf(Device).isRequired,
  children: PropTypes.node.isRequired
};

const styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    alignItems: 'stretch',
    minHeight: '100vh',
    overflowX: 'hidden',
    zIndex: 0,
  },
  header: {
    position: 'fixed',
    width: '100%',
    zIndex: 2,
  },
  content: {
    order: 0,
    flex: '1 1 auto',
    alignSelf: 'auto',
    marginTop: '82px',
    minHeight: '196px',
    position: 'relative'
  },
  footer: {
    order: 0,
    flex: '0 1 auto',
    alignSelf: 'auto'
  }
};

export default listenWindowResize(Radium(BasicLayout));
