import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Heading from '../../components/Heading.react';
import Loader from '../../components/Loader.react';
import Product from '../../components/ProductItem.react';
import Products from '../../components/ProductsList.react';
import Header from '../Category/Header.react';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';
import Searcher from '../../Home/Searcher.react';

const Search = ({ materials = [], products = [], sizes = [] }) => {
  const { size = 'all', material = 'all' } = useParams();

  if (products.length === 0) return <Loader />;

  // Parameters title
  const materialTitle = material === 'all' ? 'všechny' : materials.find(m => m.uid === material)?.data.title || 'Unknown';
  const sizeTitle = size === 'all' ? 'všechny' : sizes.find(s => s.uid === size)?.data.title || 'Unknown';

  // shared filters
  const materialFilter = p => p.data.materials.filter(m => m.material.uid === material).length > 0;
  const sizeFilter = p => p.data.sizes.filter(s => s.size.uid === size).length > 0;

  // filtered products
  const productsByMaterialAndSize = products.filter(materialFilter).filter(sizeFilter);
  const productsByMaterial = products.filter(materialFilter);
  const productsBySize = products.filter(sizeFilter);

  return (
    <Layout>
      <Helmet title="Vyhledávání" />
      <Header>
        <Heading>Vyhledávání</Heading>
        Výsledky vyhledávání strojů s pracovní plochou o velikosti <b>{sizeTitle}</b> podporující materiál <b>{materialTitle}</b>
      </Header>
      {material === 'all' && size === 'all' &&
        <div style={{ textAlign: 'center' }}>
          <Container>
            <Heading level={3}>Uf, to by bylo strojů, vyberte alespoň jeden parametr</Heading>
          </Container>
          <Searcher />
        </div>
      }
      <Container>
        {material !== 'all' && size !== 'all' &&
          <Products>
            {productsByMaterialAndSize.map(p => <Product key={p.uid} product={p} />)}
          </Products>
        }
        {material !== 'all' &&
          <div>
            <Heading level={3}>Všechny stroje pro materiál {materialTitle}</Heading>
            <Products>
              {productsByMaterial.map(p => <Product key={p.uid} product={p} />)}
            </Products>
          </div>
        }
        {size !== 'all' &&
          <div>
            <Heading level={3}>Stroje s pracovní plochou {sizeTitle} pro libovolný materiál</Heading>
            <Products>
              {productsBySize.map(p => <Product key={p.uid} product={p} />)}
            </Products>
          </div>
        }
      </Container>
    </Layout>
  );
};

Search.defaultProps = {
  materials: [],
  products: [],
  sizes: [],
};

Search.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  sizes: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  categories: state.content.categories,
  materials: state.content.materials,
  products: state.content.products,
  sizes: state.content.sizes,
});

export default connect(
  mapStateToProps,
  null
)(Search);
