import { RichText } from "prismic-reactjs"
import React, { PureComponent as Component } from "react"
import PropTypes from 'prop-types';
import Helmet from "react-helmet"
import { connect } from "react-redux"
import Heading from "../../components/Heading.react"
import Image from "../../components/Image.react"
import Loader from "../../components/Loader.react"
import ContentProvider from "../../ContentProvider.react"
import theme from "../../styleConstants"
import Container from "../Layout/Container.react"
import Layout from "../Layout/Basic.react"
import Header from "../Category/Header.react"

class Bazaar extends Component {
  render() {
    const { bazaar } = this.props

    return (
      <ContentProvider fetch={["bazaar"]}>
        {Object.keys(bazaar).length ? (
          <Layout>
            <Helmet title="Bazar" />
            <Header>
              <Heading>{RichText.asText(bazaar.title)}</Heading>
              {RichText.render(bazaar.perex)}
            </Header>
            <Container>
              <div style={styles.files}>
                {bazaar.items.length > 0 &&
                  bazaar.items.map((doc, i) => (
                    <div key={i} style={styles.item}>
                      <Image src={doc.image.url} style={styles.image} />
                      <div>
                        <h2 style={styles.heading}>
                          {RichText.asText(doc.name)}
                        </h2>
                        <p>{RichText.render(doc.description)}</p>
                        <p style={styles.price}>{RichText.render(doc.price)}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </Container>
          </Layout>
        ) : (
          <Loader />
        )}
      </ContentProvider>
    )
  }
}

const styles = {
  files: {},
  item: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.secondary}`,
    padding: "20px",
  },
  image: {
    boxShadow: "1 1 16px 0 rgba(0, 0, 0, .15)",
    maxHeight: "120px",
    maxWidth: "120px",
    marginRight: "20px",
    marginTop: "4px",
  },
  button: {},
  heading: {
    margin: 0,
  },
  price: {
    marginTop: "8px",
    fontSize: "1.3em",
  },
}

Bazaar.defaultProps = {
  bazaar: {},
}

Bazaar.propTypes = {
  bazaar: PropTypes.shape({}),
}

const mapStateToProps = state => ({
  bazaar: state.content.bazaar,
})

export default connect(mapStateToProps)(Bazaar)
