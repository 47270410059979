import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../styleConstants';
import Icon from './Icon.react';

/* eslint-disable react/no-array-index-key */
const Price = ({ value }) => (
  <div style={styles.wrapper}>
    <div style={styles.values}>
      {[...Array(5)].map((_, i) =>
        <Icon
          color={i < value ? 'white' : 'rgba(255, 255, 255, .4)'}
          key={`${i}`}
          kind="price"
          size={24}
          style={styles.icon}
        />
      )}
    </div>
    <div style={styles.legend}>Orientační cenová hladina pro porovnání s dalšími produkty</div>
  </div>
);

Price.propTypes = {
  value: PropTypes.number
};

Price.defaultProps = {
  value: 0
};

const styles = {
  wrapper: {
    backgroundColor: colors.green,
    borderRadius: '3px',
    boxShadow: '0 3px 8px rgba(0, 0, 0, .2)',
    display: 'inline-block',
    height: '90px',
    margin: '0 12px 12px 0',
    padding: '8px',
    width: '180px',
  },
  values: {
    fontSize: '22px',
    padding: '6px 0'
  },
  legend: {
    borderTop: '1px solid white',
    color: 'white',
    fontSize: '10px',
    paddingTop: '4px'
  },
  icon: {
    marginRight: '3px'
  }
};

export default Radium(Price);
