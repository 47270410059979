import { RichText } from 'prismic-reactjs';
import * as R from 'ramda';
import Radium, { Style } from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-grid-gallery';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Breadcrumbs.react';
import Button from '../../components/Button.react';
import ContactForm from '../../components/ContactForm.react';
import Heading from '../../components/Heading.react';
import Html from '../../components/Html.react';
import Image from '../../components/Image.react';
import Loader from '../../components/Loader.react';
import listenWindowResize, { Device } from '../../listenWindowResize.react';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';
import Price from '../../components/Price.react';
import Profi from '../../components/Profi.react';
import RelatedItem from '../../components/ProductItem.react';
import RelatedList from '../../components/RelatedList.react';
import routes from '../../routes';
import { media } from '../../styleConstants';
import { useParams } from 'react-router-dom';

// import Parameters from './Parameters.react';

function Product({ device, products }) {
  const { productId } = useParams();

  if (!products.length) return <Loader />;

  const product = R.find(R.propEq('uid', productId))(products);

  const gallery = product.data.gallery.length > 0 ? product.data.gallery : false;

  const relatedProducts = product.data.related.filter(rel => rel.product.uid);

  let paths = [];
  if (product.data.categories.length) {
    paths = product.data.categories.reduce((acc, cat) => [
      ...acc,
      {
        label: RichText.asText(cat.category.data.name),
        key: cat.category.uid,
        to: `${routes.category}/${cat.category.uid}`
      }
    ], []);
    paths.push({
      active: true,
      key: 'product',
      label: RichText.asText(product.data.name),
    });
  }

  return (
    <Layout>
      <Helmet title={RichText.asText(product.data.name)} />
      <Breadcrumbs paths={paths} />
      <Container>
        <div style={styles.wrapper}>
          <div style={styles.column_left}>

            {device.atLeast('md') &&
              <div>
                <Image src={product.data.image.url} style={styles.mainImage} />
                <br />
                {!!gallery &&
                  <div style={styles.gallery}>
                    <Gallery
                      enableImageSelection={false}
                      images={gallery.map(img => ({
                        src: img.image.url,
                        thumbnail: img.image.thumb.url,
                        thumbnailWidth: 180,
                        thumbnailHeight: 180
                      }))}
                      imageCountSeparator=" z "
                      rowHeight={120}
                    />
                    <div style={{ clear: 'left' }} />
                  </div>
                }
              </div>
            }

            {product.data.video && device.atLeast('md') &&
              <div className="videoWrapper" style={styles.videoWrapper}>
                <Style
                  scopeSelector=".videoWrapper"
                  rules={{
                    iframe: {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }
                  }}
                />
                <Html style={styles.video}>{product.data.video.html}</Html>
              </div>
            }

            {/* <Parameters product={product} /> */}

          </div>
          <div style={styles.column_right}>
            <Heading>{RichText.asText(product.data.name)}</Heading>
            {RichText.render(product.data.description)}

            {product.data.document.url &&
              <a href={product.data.document.url} target="_blank" rel="noopener noreferrer">
                <Button>více informací</Button>
              </a>
            }

            <div style={{ margin: '32px 0' }}>
              <Price value={Number(product.data.price)} />
              <Profi value={Number(product.data.profi)} />
            </div>
            {device.atMost('m') &&
              <div>
                <Image src={product.data.image.url} style={styles.mainImage} />
                <br />
                {gallery &&
                  <div style={styles.gallery}>
                    <Gallery
                      enableImageSelection={false}
                      images={gallery.map(img => ({
                        src: img.image.url,
                        thumbnail: img.image.thumb.url,
                        thumbnailWidth: 180,
                        thumbnailHeight: 180
                      }))}
                      imageCountSeparator=" z "
                      rowHeight={120}
                    />
                    <div style={{ clear: 'left' }} />
                  </div>
                }
              </div>
            }
            <div name="more_info">
              {RichText.render(product.data.content)}
            </div>
            <Button to={routes.contacts}>Kontakt na prodejce</Button>
            <ContactForm related={RichText.asText(product.data.name)} />
          </div>
        </div>
        {relatedProducts.length > 0 &&
          <RelatedList>
            {relatedProducts.map(rel =>
              <RelatedItem
                key={rel.product.uid}
                product={products.find(p => p.id === rel.product.id)}
              />
            )}
          </RelatedList>
        }
        <br />
      </Container>
    </Layout>
  );
}

const styles = {
  wrapper: {
    [media.m]: {
      display: 'flex',
      margin: '0 -16px',
    }
  },
  column_left: {
    [media.m]: {
      flex: '1 1 auto',
      padding: '0 16px',
      width: '40%'
    }
  },
  column_right: {
    [media.m]: {
      flex: '1 1 auto',
      padding: '0 16px',
      width: '60%'
    }
  },
  mainImage: {
    width: '100%'
  },
  gallery: {
    margin: '32px 0',
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '25px',
    height: 0
  },
};

Product.defaultProps = {
  products: [],
};

Product.propTypes = {
  device: PropTypes.instanceOf(Device).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  products: state.content.products,
});

export const ProductPage = connect(mapStateToProps)(listenWindowResize(Radium(Product)));
