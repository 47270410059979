import { RichText } from "prismic-reactjs"
import React, { PureComponent as Component } from "react"
import PropTypes from 'prop-types';
import Helmet from "react-helmet"
import { connect } from "react-redux"
import Button from "../../components/Button.react"
import Loader from "../../components/Loader.react"
import ContentProvider from "../../ContentProvider.react"
import theme from "../../styleConstants"
import Container from "../Layout/Container.react"
import Layout from "../Layout/Basic.react"
// import Header from "../Category/Header.react"

class Files extends Component {
  render() {
    const { files } = this.props

    return (
      <ContentProvider fetch={["files"]}>
        {files.length ? (
          <Layout>
            <Helmet title="Soubory" />
            {/* <Header>
              <Heading>{RichText.asText(certificates.title)}</Heading>
              {RichText.render(certificates.content)}
            </Header> */}
            <Container>
              <div style={styles.files}>
                {files.map(doc => (
                  <div key={doc.id} style={styles.item}>
                    <div>
                      <h2 style={styles.heading}>
                        {RichText.asText(doc.data.name)}
                      </h2>
                      <p style={styles.description}>
                        {RichText.render(doc.data.description)}
                      </p>
                      <p style={styles.link}>{doc.data.link.url}</p>
                    </div>
                    <a
                      href={doc.data.link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button style={styles.button}>stáhnout soubor</Button>
                    </a>
                  </div>
                ))}
              </div>
            </Container>
          </Layout>
        ) : (
          <Loader />
        )}
      </ContentProvider>
    )
  }
}

const styles = {
  files: { padding: "32px 0" },
  item: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.secondary}`,
    padding: "20px",
  },
  image: {
    boxShadow: "1 1 16px 0 rgba(0, 0, 0, .15)",
    maxHeight: "120px",
    maxWidth: "120px",
  },
  button: {},
  heading: {
    margin: 0,
  },
  description: {},
  link: {
    color: "#aaa",
    fontSize: "10px",
  },
}

Files.defaultProps = {
  files: [],
}

Files.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = state => ({
  files: state.content.files,
})

export default connect(mapStateToProps)(Files)
