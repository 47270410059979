import moment from 'moment';
import { Date, RichText } from 'prismic-reactjs';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs.react';
import Heading from '../../components/Heading.react';
import Loader from '../../components/Loader.react';
import ContentProvider from '../../ContentProvider.react';
import theme from '../../styleConstants';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';

class Blog extends Component {

  render() {
    const { articles } = this.props;

    return (
      <Layout>
        <Helmet title="Blog" />
        <Breadcrumbs />
        <Container>
          <Heading>Blog</Heading>
          <ContentProvider fetch={['articles']}>
            <div style={styles.articles}>
              {Object.keys(articles).length ?
                articles.map(article =>
                  <div key={article.uid} style={styles.item}>
                    <div style={styles.date}>{moment(Date(article.data.published)).format('dddd, Do MMMM YYYY')}</div>
                    <div style={styles.heading}>{RichText.asText(article.data.title)}</div>
                    <div style={styles.content}>{RichText.render(article.data.content)}</div>
                  </div>
                ) :
                <Loader />
              }
            </div>
          </ContentProvider>
        </Container>
      </Layout>
    );
  }
}

const styles = {
  wrapper: {
    display: 'inline-block',
    position: 'relative',
    ':hover': {},
  },
  link: {
    color: theme.secondary,
  },
  articles: {
    textAlign: 'left',
  },
  item: {
    borderBottom: `1px solid ${theme.secondary}`,
    color: theme.primary,
    marginBottom: '4px',
    padding: '8px',
  },
  itemLast: {
    borderBottom: 0
  },
  date: {
    fontSize: '10px',
    fontWeight: 700,
  },
  heading: {
    fontSize: '16px',
    fontWeight: 300,
    marginTop: '2px',
  },
  content: {
    color: '#777',
    fontSize: '11px',
  },
};

Blog.defaultProps = {
  articles: [],
};

Blog.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  articles: state.content.articles,
});

export default connect(
  mapStateToProps
)(Blog);
