import moment from 'moment';
import { Date, RichText } from 'prismic-reactjs';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs.react';
import Heading from '../../components/Heading.react';
import Loader from '../../components/Loader.react';
import ContentProvider from '../../ContentProvider.react';
import theme from '../../styleConstants';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';

class News extends Component {

  render() {
    const { news } = this.props;

    return (
      <Layout>
        <Helmet title="Kontakty" />
        <Breadcrumbs />
        <Container>
          <Heading>Novinky</Heading>
          <ContentProvider fetch={['news']}>
            <div style={styles.news}>
              {news.length ?
                news.map(n =>
                  <div key={n.uid} style={styles.item}>
                    <div style={styles.date}>{moment(Date(n.data.published)).format('dddd, Do MMMM YYYY')}</div>
                    <div style={styles.heading}>{RichText.asText(n.data.title)}</div>
                    <div style={styles.content}>{RichText.render(n.data.content)}</div>
                  </div>
                ) :
                <Loader />
              }
            </div>
          </ContentProvider>
        </Container>
      </Layout>
    );
  }
}

const styles = {
  wrapper: {
    display: 'inline-block',
    position: 'relative',
    ':hover': {},
  },
  link: {
    color: theme.secondary,
  },
  news: {
    textAlign: 'left',
  },
  item: {
    borderBottom: `1px solid ${theme.secondary}`,
    color: theme.primary,
    marginBottom: '4px',
    padding: '8px',
  },
  itemLast: {
    borderBottom: 0
  },
  date: {
    fontSize: '10px',
    fontWeight: 700,
  },
  heading: {
    fontSize: '16px',
    fontWeight: 300,
    marginTop: '2px',
  },
  content: {
    color: '#777',
    fontSize: '11px',
  },
};

News.defaultProps = {
  news: [],
};

News.propTypes = {
  news: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  news: state.content.news,
});

export default connect(
  mapStateToProps
)(News);
