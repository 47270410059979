import { RichText } from 'prismic-reactjs';
import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Heading from './Heading.react';
import Icon from './Icon.react';
import Image from './Image.react';
import Link from './Link.react';
import listenWindowResize, { Device } from '../listenWindowResize.react';
import { media } from '../styleConstants';
import routes from '../routes';

class ProductItem extends Component {
  // eslint-disable-next-line
  renderPrice(value) {
    if (value > 0) {
      return (
        <div style={styles.values}>
          {[...Array(value)].map((_, i) =>
            <Icon
              color="white"
              // eslint-disable-next-line
              key={i}
              kind="price"
              size={14}
              style={styles.icon}
            />
          )}
        </div>
      );
    }
  }

  // eslint-disable-next-line
  renderProfi(value) {
    if (value > 0) {
      return (
        <div style={styles.values}>
          {[...Array(value)].map((_, i) =>
            <Icon
              color="white"
              // eslint-disable-next-line
              key={i}
              kind="profi"
              size={14}
              style={styles.icon}
            />
          )}
        </div>
      );
    }
  }

  render() {
    const { device, product } = this.props;

    if (!product || !product.data) return null;

    return (
      <li style={styles.wrapper}>
        <Link style={styles.link} to={`${routes.product}/${product.uid}`}>
          <div style={styles.imageWrapper}>
            <Image src={product.data.image.small.url} style={styles.image} />
            {device.atLeast('l') &&
              <div style={styles.valuesWrapper}>
                {this.renderPrice(Number(product.data.price))}
                {this.renderProfi(Number(product.data.profi))}
              </div>
            }
          </div>
          <Heading level={3} style={styles.heading} title={RichText.asText(product.data.name)}>
            {RichText.asText(product.data.name)}
          </Heading>
        </Link>
        <div style={styles.description}>{RichText.asText(product.data.description)}</div>
      </li>
    );
  }
}

ProductItem.defaultProps = {
  product: {},
};

ProductItem.propTypes = {
  device: PropTypes.instanceOf(Device).isRequired,
  product: PropTypes.shape({})
};

const styles = {
  wrapper: {
    flex: '0 1 auto',
    alignSelf: 'auto',
    padding: '16px',
    textAlign: 'center',
    width: '100%',
    [media.xs]: {
      width: '50%',
    },
    [media.s]: {
      width: '33%',
    },
    [media.m]: {
      width: '25%',
    },
    [media.l]: {
      width: '20%',
    }
  },
  link: {
    color: '#000',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  imageWrapper: {
    borderRadius: '3px',
    boxShadow: '0 4px 24px rgba(0, 0, 0, .15)',
    overflow: 'hidden',
    position: 'relative'
  },
  image: {
    display: 'block',
    maxWidth: '100%'
  },
  valuesWrapper: {
    backgroundColor: 'rgba(0, 0, 0, .1)',
    borderTop: '1px solid rgba(255, 255, 255, .1)',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    left: 0,
    position: 'absolute',
    right: 0
  },
  values: {
    padding: '8px'
  },
  icon: {
    margin: '0 1px'
  },
  heading: {
    display: 'block',
    fontSize: '1.75em',
    marginBottom: '.5em',
    marginTop: '.5em',
  },
  description: {
    color: '#777',
    fontSize: '.85em'
  }
};

export default listenWindowResize(Radium(ProductItem));
