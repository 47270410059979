export const typography = {
  primaryFamily: 'Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif, sans-serif',
  secondaryFamily: 'Asap, Helvetica Neue, Helvetica, Arial, sans-serif'
};

export const colors = {
  blueDark: '#031419',
  green: '#09AD14',
  lightGray: '#F1F3F4'
};

export const sizes = {
  pageWidth: 1440
};

export const breakpoints = {
  infinity: Infinity,
  xl: 1400,
  l: 1190,
  md: 1024,
  m: 768,
  s: 480,
  xs: 320
};

export const media = {
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
  l: `@media screen and (min-width: ${breakpoints.l}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  m: `@media screen and (min-width: ${breakpoints.m}px)`,
  s: `@media screen and (min-width: ${breakpoints.s}px)`,
  xs: `@media screen and (min-width: ${breakpoints.xs}px)`,
  exactXl: `@media screen and (min-width: ${breakpoints.xl}px)`,
  exactL: `@media screen and (min-width: ${breakpoints.l}px) and (max-width: ${breakpoints.xl - 1}px)`,
  exactMD: `@media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.l - 1}px)`,
  exactM: `@media screen and (min-width: ${breakpoints.m}px) and (max-width: ${breakpoints.md - 1}px)`,
  exactS: `@media screen and (min-width: ${breakpoints.s}px) and (max-width: ${breakpoints.m - 1}px)`,
  exactXs: `@media screen and (min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.s - 1}px)`,
  maxXl: `@media screen and (max-width: ${breakpoints.xl - 1}px)`,
  maxL: `@media screen and (max-width: ${breakpoints.l - 1}px)`,
  maxMD: `@media screen and (max-width: ${breakpoints.md - 1}px)`,
  maxM: `@media screen and (max-width: ${breakpoints.m - 1}px)`,
  maxS: `@media screen and (max-width: ${breakpoints.s - 1}px)`,
  maxXs: `@media screen and (max-width: ${breakpoints.xs - 1}px)`,
  retina: '@media (-webkit-min-device-pixel-ratio: 2)'
};

const theme = {
  container: `${sizes.pageWidth}px`,
  primary: colors.blueDark,
  secondary: colors.green,
  text: typography.primaryFamily
};

export default theme;
