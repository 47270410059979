import * as actions from "../actions/content"

const initialState = {
  about: {},
  articles: [],
  bazaar: {},
  categories: [],
  contacts: {},
  certificatesPage: {},
  files: [],
  gdpr: {},
  homepage: {},
  legal: {},
  materials: [],
  materialsPage: {},
  news: [],
  products: [],
  sizes: [],
  support: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ABOUT_SUCCESS:
      return { ...state, about: action.payload }

    case actions.GET_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload }

    case actions.GET_FILES_SUCCESS:
      return { ...state, files: action.payload }

    case actions.GET_ARTICLES_SUCCESS:
      return { ...state, articles: action.payload }

    case actions.GET_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload }

    case actions.GET_CERTIFICATES_SUCCESS:
      return { ...state, certificatesPage: action.payload }

    case actions.GET_BAZAAR_SUCCESS:
      return { ...state, bazaar: action.payload }

    case actions.GET_CONTACTS_SUCCESS:
      return { ...state, contacts: action.payload }

    case actions.GET_GDPR_SUCCESS:
      return { ...state, gdpr: action.payload }

    case actions.GET_HOMEPAGE_SUCCESS:
      return { ...state, homepage: action.payload }

    case actions.GET_LEGAL_SUCCESS:
      return { ...state, legal: action.payload }

    case actions.GET_MATERIALS_SUCCESS:
      return { ...state, materials: action.payload }

    case actions.GET_MATERIALS_PAGE_SUCCESS:
      return { ...state, materialsPage: action.payload }

    case actions.GET_NEWS_SUCCESS:
      return { ...state, news: action.payload }

    case actions.GET_SIZES_SUCCESS:
      return { ...state, sizes: action.payload }

    case actions.GET_SUPPORT_SUCCESS:
      return { ...state, support: action.payload }

    default:
      return state
  }
}

export default reducer
