import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader.react';

class Image extends Component {
  constructor(props) {
    super(props);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.state = { loaded: false };
  }

  handleImageLoaded = () => {
    this.setState({ loaded: true });
  }

  render() {
    const { alt, ...rest } = this.props;
    const { loaded } = this.state;

    return (
      <div style={styles.wrapper}>
        <div style={styles.container}>
          {!loaded && <Loader size={48} style={{ position: 'absolute' }} />}
          <img alt={alt} onLoad={this.handleImageLoaded} {...rest} />
        </div>
      </div>
    );
  }
}

Image.propTypes = {
  alt: PropTypes.string,
};

Image.defaultProps = {
  alt: 'Gravotech'
};

const styles = {
  wrapper: {
    display: 'inline-block',
    maxWidth: '100%',
    position: 'relative'
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'middle',
    minHeight: '48px',
    minWidth: '48px',
    position: 'relative'
  }
};

export default Radium(Image);
