import { RichText } from 'prismic-reactjs';
import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';
import Hexagon from '../../components/Hexagon.react';
import Icon from '../../components/Icon.react';
import Link from '../../components/Link.react';
import routes from '../../routes';

const Subcategory = ({ category }) => (
  <li style={styles.wrapper}>
    <Link style={styles.link} to={`${routes.category}/${category.uid}`}>
      <Hexagon>
        <Icon kind={category.data.icon || 'laser-machine'} color="#fff" size={42} style={styles.icon} />
        <div style={styles.text}>{RichText.asText(category.data.name)}</div>
      </Hexagon>
    </Link>
  </li>
);

Subcategory.propTypes = {
  category: PropTypes.shape({}).isRequired
};

const styles = {
  wrapper: {
    flex: '0 0 auto',
    alignSelf: 'auto',
    listStyle: 'none',
    marginRight: '2px',
    padding: 0,
    textAlign: 'center',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
  text: {
    lineHeight: 1,
    fontSize: '13px',
    fontWeight: 700,
    marginTop: '2px',
  },
  icon: {
    marginTop: '-2px',
  }
};

export default Radium(Subcategory);
