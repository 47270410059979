/* eslint-disable react/no-multi-comp */

import React from 'react';
import { List } from 'immutable';
import { breakpoints } from './styleConstants';

let devices = [];

export class Device {

  constructor(name, maxWidth) {
    this.name = name;
    this.maxWidth = maxWidth;
  }

  atLeast(sizeName) {
    const searchDevice = devices.find(device => device.name === sizeName);
    return devices.indexOf(searchDevice) <= devices.indexOf(this);
  }

  atMost(sizeName) {
    const searchDevice = devices.find(device => device.name === sizeName);
    return devices.indexOf(searchDevice) >= devices.indexOf(this);
  }
}

devices = List([
  new Device('xs', breakpoints.xs),
  new Device('s', breakpoints.s),
  new Device('m', breakpoints.m),
  new Device('md', breakpoints.md),
  new Device('l', breakpoints.l),
  new Device('xl', breakpoints.xl),
  new Device('infinity', 5000),
]);
const defaultDevice = devices.get(2); // md

export default function listenWindowResize(Component) {
  if (typeof document === 'undefined') {
    return class extends React.Component {

      static displayName = `${Component.name}ListenWindowResize`

      render() {
        return <Component {...this.props} device={defaultDevice} />;
      }
    };
  }

  return class extends React.Component {

    static displayName = `${Component.name}ListenWindowResize`

    state = {
      device: this.findDevice()
    }

    constructor(props) {
      super(props);
      this.handleResize = this.handleResize.bind(this);
    }

    handleResize() {
      const device = this.findDevice();

      if (device !== this.state.device) {
        this.setState({ device });
      }
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    // eslint-disable-next-line
    findDevice() {
      return (
        devices.find(device => device.maxWidth > window.innerWidth) || defaultDevice
      );
    }

    render() {
      return <Component {...this.props} {...this.state} />;
    }
  };
}

/* eslint-enable react/no-multi-comp */
