import { RichText } from 'prismic-reactjs';
import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import ContentProvider from '../../ContentProvider.react';
import Heading from '../../components/Heading.react';
import Loader from '../../components/Loader.react';
import Container from '../Layout/Container.react';
import Layout from '../Layout/Basic.react';
import hexagonsBg from '../../assets/images/hexagons_outlined.jpg';

class Gdpr extends Component {
  render() {
    const { gdpr } = this.props;

    return (
      <Layout>
        <ContentProvider fetch={['gdpr']}>
          <div style={styles.wrapper}>
            <Container>
              {Object.keys(gdpr).length ?
                <div>
                  <Helmet title={RichText.asText(gdpr.title)} />
                  <Heading>{RichText.asText(gdpr.title)}</Heading>
                  {RichText.render(gdpr.content)}
                </div> :
                <Loader />
              }
            </Container>
          </div>
        </ContentProvider>
      </Layout>
    );
  }
}

Gdpr.defaultProps = {
  gdpr: {},
};

Gdpr.propTypes = {
  gdpr: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  gdpr: state.content.gdpr,
});

export default connect(
  mapStateToProps
)(Radium(Gdpr));

const styles = {
  wrapper: {
    backgroundImage: `url(${hexagonsBg})`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '66% auto',
    margin: 0,
    padding: '24px 0',
    width: '100%'
  },
};
