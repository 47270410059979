import Radium from 'radium';
import React from 'react';
import PropTypes from 'prop-types';

const Heading = ({ children, level, style }) => (
  React.createElement(
    `h${level}`,
    {
      style: [
        styles.base,
        styles[`h${level}`],
        style
      ]
    },
    children
  )
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ])
};

Heading.defaultProps = {
  level: 1,
  style: {}
};

const styles = {
  base: {
    fontWeight: 700,
    marginBottom: '.185em',
    marginTop: '.225em'
  },
  h1: {
    fontFamily: 'Asap, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: '2.8em'
  },
  h2: {
    fontSize: '2.45em'
  },
  h3: {
    fontSize: '1.4em',
    fontWeight: 400
  },
  h4: {
    fontSize: '1.15em',
    fontWeight: 600
  }
};

export default Radium(Heading);
