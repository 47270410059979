import fetch from 'isomorphic-fetch';

export const CLEAR_REQUEST = 'CLEAR_REQUEST';
export const SEND_REQUEST = 'SEND_REQUEST';
export const SEND_REQUEST_START = 'SEND_REQUEST_START';
export const SEND_REQUEST_SUCCESS = 'SEND_REQUEST_SUCCESS';
export const SEND_REQUEST_ERROR = 'SEND_REQUEST_ERROR';

export const sendRequest = (subject, content) => {
  const promise = fetch('/mail/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      subject,
      content,
    })
  })
  .then((response) => {
    if (response.status >= 400) {
      throw new Error('Bad response from server');
    }
    return response.json();
  })
  .then(response => response);

  return {
    type: SEND_REQUEST,
    payload: promise
  };
};

export const clearRequestStatus = () => (
  {
    type: CLEAR_REQUEST
  }
);
