import React, { PureComponent as Component } from "react"
import PropTypes from 'prop-types';
import { connect } from "react-redux"
import * as contentActions from "./common/actions/content"

class ContentProvider extends Component {
  componentDidMount() {
    const {
      content,
      getAbout,
      getArticles,
      getBazaar,
      getCategories,
      getCertificates,
      getContacts,
      getFiles,
      getGdpr,
      getHomepage,
      getLegal,
      getMaterialsPage,
      getNews,
      getProducts,
      getSearcherValues,
      fetch,
      force,
    } = this.props

    if (
      fetch.includes("about") &&
      (force || Object.keys(content.about).length === 0)
    ) {
      getAbout()
    }

    if (
      fetch.includes("articles") &&
      (force || Object.keys(content.articles).length === 0)
    )
      getArticles()

    if (
      fetch.includes("categories") &&
      (force || Object.keys(content.categories).length === 0)
    )
      getCategories()

    if (fetch.includes("files") && (force || content.files.length === 0))
      getFiles()

    if (
      fetch.includes("contacts") &&
      (force || Object.keys(content.contacts).length === 0)
    )
      getContacts()

    if (
      fetch.includes("gdpr") &&
      (force || Object.keys(content.gdpr).length === 0)
    )
      getGdpr()

    if (
      fetch.includes("certificates") &&
      (force || Object.keys(content.certificatesPage).length === 0)
    )
      getCertificates()

    if (
      fetch.includes("bazaar") &&
      (force || Object.keys(content.bazaar).length === 0)
    )
      getBazaar()

    if (
      fetch.includes("homepage") &&
      (force || Object.keys(content.homepage).length === 0)
    ) {
      getHomepage()
    }

    if (
      fetch.includes("legal") &&
      (force || Object.keys(content.legal).length === 0)
    )
      getLegal()

    if (
      fetch.includes("materials") &&
      (force || Object.keys(content.materialsPage).length === 0)
    )
      getMaterialsPage()

    if (
      fetch.includes("news") &&
      (force || Object.keys(content.news).length === 0)
    )
      getNews()

    if (
      fetch.includes("products") &&
      (force || Object.keys(content.products).length === 0)
    )
      getProducts()

    if (fetch.includes("searcher")) getSearcherValues()
  }

  render = () => <div>{this.props.children}</div>
}

ContentProvider.defaultProps = {
  content: {},
  force: false,
}

ContentProvider.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.shape({}),
  getArticles: PropTypes.func.isRequired,
  getAbout: PropTypes.func.isRequired,
  getBazaar: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getCertificates: PropTypes.func.isRequired,
  getContacts: PropTypes.func.isRequired,
  getFiles: PropTypes.func.isRequired,
  getGdpr: PropTypes.func.isRequired,
  getHomepage: PropTypes.func.isRequired,
  getLegal: PropTypes.func.isRequired,
  getMaterialsPage: PropTypes.func.isRequired,
  getNews: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getSearcherValues: PropTypes.func.isRequired,
  fetch: PropTypes.arrayOf(
    PropTypes.oneOf([
      "about",
      "articles",
      "bazaar",
      "categories",
      "certificates",
      "contacts",
      "files",
      "gdpr",
      "homepage",
      "legal",
      "materials",
      "news",
      "products",
      "searcher",
    ]),
  ).isRequired,
  force: PropTypes.bool,
}

const mapStateToProps = state => ({
  content: state.content,
})

const mapDispatchToProps = dispatch => ({
  getAbout: () => dispatch(contentActions.getAbout()),
  getArticles: () => dispatch(contentActions.getArticles()),
  getBazaar: () => dispatch(contentActions.getBazaar()),
  getCategories: () => dispatch(contentActions.getCategories()),
  getCertificates: () => dispatch(contentActions.getCertificates()),
  getContacts: () => dispatch(contentActions.getContacts()),
  getFiles: () => dispatch(contentActions.getFiles()),
  getGdpr: () => dispatch(contentActions.getGdpr()),
  getHomepage: () => dispatch(contentActions.getHomepage()),
  getLegal: () => dispatch(contentActions.getLegal()),
  getMaterialsPage: () => dispatch(contentActions.getMaterialsPage()),
  getNews: () => dispatch(contentActions.getLatestNews()),
  getProducts: () => dispatch(contentActions.getProducts()),
  getSearcherValues: () => dispatch(contentActions.getSearcherValues()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentProvider)
